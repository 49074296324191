<template>
  <div id="orderInfo">
    <div class="page-header">
       <p class="page-path">
        在保员工名册<span class="slashline">/</span>参保员工名册<span class="slashline">/</span>查看详细
      </p>
      <p class="page-title">参保员工详细<el-button type="primary" @click="back" style="margin-left: 10px;padding: 6px;" size="mini" icon="el-icon-back">返回</el-button></p>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="filters.name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="身份证：" prop="idCard">
              <el-input v-model="filters.idNumber" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属单位：" prop="company">
                <el-input v-model="filters.company" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="searchList" :loading="queryLoading">查询</el-button>
            <el-button type="default" @click="resetForm('filtersform')" :loading="queryLoading">重置</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="托管网点：" prop="branch">
              <el-input
                v-model="filters.branch"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所属客户：" prop="customer">
              <el-select v-model="filters.customer" placeholder="请选择">
                <el-option label="ERS" value="ERS"></el-option>
                <el-option label="FAR" value="FAR"></el-option>
                <el-option label="ESB" value="ESB"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
           <el-col :span="6">
            <el-form-item label="申报类型：" prop="applyType">
              <el-select v-model="filters.applyType" clearable placeholder="请选择">
                <el-option label="新增" value="0"></el-option>
                <el-option label="续保" value="1" v-show="type==0"></el-option>
                <el-option label="调入" value="1" v-show="type==1"></el-option>
                <el-option label="停保" value="2" v-show="type==0"></el-option>
                <el-option label="封存" value="2" v-show="type==1"></el-option>
                <el-option label="变更" value="3"></el-option>
                <el-option label="补缴" value="4"></el-option>
                <el-option label="退保" value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="受理状态：" prop="applyStatus">
              <el-select multiple v-model="filters.applyStatus" placeholder="请选择">
                <el-option label="待受理" value="0"></el-option>
                <el-option label="已受理" value="1"></el-option>
                <el-option label="退回" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div>
        <el-button type="default" @click="exportExcel">导出数据</el-button>
      </div>

      <el-table
        ref="BusinessAcceptanceTable"
        max-height="500"
        :data="BusinessAcceptanceTableData"
        border
        style="margin: 32px 0 30px"
        v-loading="queryLoading"
      >
        <el-table-column type="selection" width="48"> </el-table-column>
          <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="50">
        </el-table-column>
        <el-table-column label="申报类型">
          <template slot-scope="scope">
            {{ getApplyType(scope.row.applyType) }}
          </template>
        </el-table-column>
        <el-table-column label="参保类型" prop="insuranceType"></el-table-column>
        <el-table-column label="生效月份" prop="effectiveMonth"></el-table-column>
        <el-table-column label="实缴月份" prop="paidMonth"></el-table-column>
        <el-table-column label="托管网点" prop="branch" width="100"></el-table-column>
        <el-table-column label="所属客户" prop="customer"> </el-table-column>
        <el-table-column label="申报基数" prop="applyBaseNum"></el-table-column>
        <el-table-column label="政策编号" prop="policyNumber"></el-table-column>
        <el-table-column label="申报时间" prop="addTime" width="90"></el-table-column>
        <el-table-column label="受理时间" prop="processTime" width="90"></el-table-column>
        <el-table-column label="受理状态">
          <template slot-scope="scope">
            {{getApplyStatus(scope.row.applyStatus)}}
          </template>
        </el-table-column>
        <el-table-column label="反馈进度" width="90">
            <template slot-scope="scope">
                    {{ scope.row.feedbackProcess > 3?"反馈完成":"未反馈完成" }}
                      <p>(状态码: {{ scope.row.feedbackProcess }})</p>
            </template>
        </el-table-column>

        <!-- <el-table-column label="订单编号" prop="orderNum"></el-table-column> -->
        <el-table-column label="第三方编号" prop="thirdPartNum" width="130"></el-table-column>

        <el-table-column label="个人信息" width="120">
          <template slot-scope="scope">
            <el-button type="default" @click="personnelInformation(scope.row)">个人信息</el-button>
          </template>
        </el-table-column>
        <el-table-column label="反馈详细" width="100">
          <template slot-scope="scope">
            <el-button v-show="scope.row.applyStatus==1" type="primary" @click="seenFeedBack(scope.row.thirdPartNum)">反馈详细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="个人信息"
      :visible.sync="PersonnelInformationDialog"
      width="700px"
      @close="PersonnelInformationDialogTableData = []"
    >
      <el-table
        ref="PersonnelDataDialogTable"
        max-height="500"
        :data="PersonnelInformationDialogTableData"
        border
        style="margin: 32px 0 30px"
      >
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column label="名称" prop="key"> </el-table-column>
        <el-table-column label="值" prop="value"> </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PersonnelInformationDialog = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="反馈详细" :visible.sync="showInfofb">
      <el-table :data="fbData" ref="fbTable">
        <el-table-column
          type="index"
          label="序号"
          width="50">
        </el-table-column>
        <el-table-column prop="insName" label="险种名称"></el-table-column>
        <el-table-column label="反馈状态">
          <template slot-scope="scope">
            <span v-if="scope.row.feedBackStatus == 0">待反馈</span>
             <span v-else-if="scope.row.feedBackStatus == 1">成功</span>
             <span v-else-if="scope.row.feedBackStatus == 2">失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="feedBackDetails" label="反馈失败原因"></el-table-column>
        <el-table-column prop="applyBaseNum" label="申报基数"></el-table-column>
        <el-table-column prop="effectiveMonth" label="生效月份"></el-table-column>
        <el-table-column prop="paidMonth" label="实缴月份"></el-table-column>
        <el-table-column prop="feedBackTime" label="反馈时间"></el-table-column>
        <el-table-column prop="pendingStatus" label="异常反馈"></el-table-column>
      </el-table>
    </el-dialog>



  </div>
</template>

<script>
export default {
  name: "OrderInfo",
  data: function () {
    return {
      fbQueryLoading:false,
      queryLoading:false,
      showInfofb:false,
      activeKutab: -1,
      filters: {
        name: "",
        idNumber: "",
        company: "",
        branch: "",
        customer: "",
        enterpriseId:"",
        applyType:"",
        applyStatus:[],
      },
      type:0,
      BusinessAcceptanceTableData: [],
      totalCount: 0,
      ImportDataDialog: false,
      ImportDataForm: {
        company: "",
      },
      ImportDataFormRules: {
        company: [
          { required: true, message: "请选择单位名称", trigger: "change" },
        ],
      },
      PersonnelInformationDialog: false,
      PersonnelInformationDialogTableData: [],
      pageSize: 10,
      currentPage: 1,
      fbData:[],
    };
  },
  methods: {
    indexMethod(index) { //实现表格序号自增
        let curpage = this.currentPage;
        let limitpage = this.pageSize;
        return (index + 1) + (curpage - 1) * limitpage;
    },
    getApplyType(applyType) {
      switch (applyType) {
        case 0:
          return "社保新增";
        case 1:
          return "社保续保";
        case 2:
          return "社保停保";
        case 3:
          return "社保变更";
        case 4:
          return "社保补缴";
        case 5:
          return "社保退保";
        case 6:
          return "公积金新增";
        case 7:
          return "公积金调入";
        case 8:
          return "公积金封存";
        case 9:
          return "公积金变更";
        case 10:
          return "公积金补缴";
        case 11:
          return "公积金退保";
        default:
          return "";
      }
    },
    getApplyStatus(applyStatus) {
      switch (applyStatus) {
        case 0:
          return "待受理";
        case 1:
          return "已受理";
        case 2:
          return "退回";
        default:
          return "";
      }
    },
    personnelInformation(row) {
      // 个人信息
      this.getPersonnelInformationDetail(row.id);
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
    },
    resetForm() {
       this.filters.branch="";
       this.filters.applyType="";
       this.filters.customer="",
       this.filters.applyStatus=[];
       this.searchList();
    },
    async getPersonnelInformationDetail(id) {
      let params = {
        id: id,
      };
      try {
        let res = await this.$api.orderProcess.detail(params);
        if (res.code == 200) {
          this.PersonnelInformationDialogTableData = res.data;
          this.PersonnelInformationDialog = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    searchList() {
      this.currentPage = 1;
      this.pageSize = 10;
      this.getData();
    },
    async getData() {
      this.queryLoading=true;
      let listParams = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        idCard: this.filters.idNumber,
        applyType: this.filters.applyType,
        enterpriseName: this.filters.company,
        branch: this.filters.branch,
        // branchId: 0,
        customer: this.filters.customer,
        seenHistory:false,
        seenDelineTime:false,
        MulApplyStatus:this.filters.applyStatus,
        type:this.type
      };
      if(this.type==1 && this.filters.applyType!="")
      {
          listParams.applyType = Number(listParams.applyType) + 6;
      }
      try {
        let listData = await this.$api.orderProcess.list(listParams);
        this.totalCount = listData.data.totalCount;
        this.BusinessAcceptanceTableData = listData.data.item;
        this.queryLoading=false;
      } catch (err) {
        console.log(err);
        this.queryLoading=false;
      }
    },
    back(){
        this.$router.back();
    },
    async seenFeedBack(thirdPartNum)
    {
        this.fbQueryLoading = true;
        this.showInfofb = true;
        let listParams = {
            pageCount: 1,
            takeCount: 30,
            thirdPartNum:thirdPartNum,
        };
        try {
            let data = await this.$api.orderFeedback.list(listParams);
            console.log(data.item);
            this.fbData = data.data.item;
        } catch (error) {
            console.log(error);
            this.fbQueryLoading = false;
        }
        this.fbQueryLoading = false;
    },
    exportExcel(){
      let selection = this.$refs.BusinessAcceptanceTable.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        ids.push(item.id);
      }
      this.$confirm("确认导出选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
            this.$api.orderProcess.export({ids:ids})
            .then((response) =>{
            let fileName = this.filters.name+"_业务受理"+new Date().toLocaleString('en-GB') + '.zip';
            this.$downloadByFileBytes(response,fileName);
            this.getData();
          });
        }).catch(() => {});
    }
  },
  activated() {
    debugger
  console.log('activated', this.$route.query);
  this.filters.name = this.$route.query.name;
  this.filters.idNumber = this.$route.query.idCard;
  this.filters.company = this.$route.query.enterpriseName;
  this.filters.enterpriseId = this.$route.query.enterpriseId;
  this.type = this.$route.query.type;
  this.getData();
}
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#BusinessAcceptance {
  padding: 20px;
}
#BusinessAcceptance .operation-pannel {
  padding-top: 0;
}
</style>
