<template>
  <div id="InsuredEmployee">
    <div class="page-header">
      <p class="page-path">
        在保员工名册<span class="slashline">/</span>参保员工名册
      </p>
      <p class="page-title">参保员工名册</p>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="160px"
        ref="filtersform"
        style="padding-top: 20px"
      >
        <el-row >
          <el-col :span="4">
            <el-form-item label="所属单位：" prop="enterpriseName">
              <el-input
                class="responsive-input"
                v-model="filters.enterpriseName"
                placeholder="请输入单位名称"
                style="max-width: 180px !important; "
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="员工姓名：" prop="name">
              <el-input
              class="responsive-input"
                v-model="filters.name"
                placeholder="请输入姓名"
                style="max-width: 180px !important;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="身份证：" prop="idCard">
              <el-input
              class="responsive-input"
                v-model="filters.idCard"
                placeholder="请输入身份证"
                style="max-width: 180px !important;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="托管网点1" prop="branch">
              <el-input
              class="responsive-input"
                v-model="filters.branch"
                placeholder="请输入网点名称"
                style="max-width: 180px !important; "
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="生效月份：" prop="effectiveMonth">
              <el-date-picker
              class="responsive-input"
                value-format="yyyy-MM"
                v-model="filters.effectiveMonth"
                type="month"
                placeholder="选择月"
                style="max-width: 180px !important;"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row >
          <el-col :span="4">
            <el-form-item label="所属客户：" prop="customer">
              <el-select
                v-model="filters.customer"
                clearable
                class="responsive-input"
                placeholder="请选择"
                style="max-width: 180px !important;"
              >
                <el-option label="ERS" value="ERS"></el-option>
                <el-option label="FAR" value="FAR"></el-option>
                <el-option label="ESB" value="ESB"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="参保状态：" prop="isPositive">
              <el-select
                v-model="filters.isPositive"
                clearable
                placeholder="请选择"
                class="responsive-input"
                style="max-width: 180px !important;"
              >
                <el-option label="在保" value="true"></el-option>
                <el-option label="停保" value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="是否托管：" prop="isEntrust">
              <el-select
                v-model="filters.isEntrust"
                clearable
                class="responsive-input"
                placeholder="请选择"
                style="max-width: 180px !important;"
              >
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
                <el-option label="全部" value="-1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="类型：" prop="isSo">
              <el-select
                v-model="filters.isSo"
                clearable
                class="responsive-input"
                placeholder="请选择"
                style="max-width: 180px !important; "
              >
                <el-option label="全部" value="-1"></el-option>
                <el-option label="社保" value="0"></el-option>
                <el-option label="公积金" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="在保人员来源：" prop="source">
              <el-select
                v-model="filters.source"
                clearable
                class="responsive-input"
                placeholder="请选择"
                style="max-width: 180px !important;"
              >
                <el-option label="申报" :value="0"></el-option>
                <el-option label="导入" :value="1"></el-option>
                <el-option label="初始化导入" :value="2"></el-option>
                <!-- <el-option label="全部" :value="-1"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin-bottom: 15px">
        <el-button type="primary" @click="searchList" :loading="queryLoading"
          >查询</el-button
        >
        <el-button
          type="default"
          @click="resetForm('filtersform')"
          :loading="queryLoading"
          >重置</el-button
        >
        <el-button
          type="danger"
          style="margin-bottom: 10px"
          v-show="importShow"
          @click="ImportDataDialog = true"
          >导入数据</el-button
        >
        <el-button
          type="warning"
          style="margin-bottom: 10px"
          v-show="exportShow"
          @click="exportData()"
          :disabled="exportDis"
          >导出数据</el-button
        >
      </div>
      <el-table
        ref="insuredEmployeeTable"
        max-height="500"
        :data="insuredEmployeeTableData"
        border
        style="margin: 0px 0 30px"
        v-loading="queryLoading"
      >
        <el-table-column type="selection" width="48"> </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="50"
        >
        </el-table-column>
        <el-table-column label="姓名" prop="name"> </el-table-column>
        <el-table-column
          label="身份证号"
          prop="idCard"
          width="190"
        ></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="所属客户" prop="customer"></el-table-column>
        <el-table-column
          label="所属单位"
          width="100"
          prop="enterpriseName"
        ></el-table-column>
        <el-table-column label="是否托管" prop="isEntrust">
          <template slot-scope="scope">
            <span v-if="scope.row.isEntrust == true" style="color: green"
              >是</span
            >
            <span v-else-if="scope.row.isEntrust == false" style="color: red"
              >否</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="托管网点"
          width="200"
          prop="branch"
        ></el-table-column>
        <el-table-column label="在保人员来源" width="120px" prop="source"
          ><template slot-scope="scope">
            <span v-if="scope.row.source == 0">申报</span>
            <span v-if="scope.row.source == 1">导入</span>
            <span v-if="scope.row.source == 2">初始化导入</span>
          </template>
        </el-table-column>
        <el-table-column label="参缴城市" prop="city"></el-table-column>
        <el-table-column
          label="生效月份"
          prop="effectiveMonth"
        ></el-table-column>
        <el-table-column label="申报基数" prop="applyBaseNum"></el-table-column>
        <el-table-column label="参保状态">
          <template slot-scope="scope">
            <span v-if="scope.row.isPositive == true">在保</span>
            <span v-else-if="scope.row.isPositive == false">停保</span>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">社保</span>
            <span v-else-if="scope.row.type == 1">公积金</span>
          </template>
        </el-table-column>
        <el-table-column label="参保轨迹" width="100">
          <template slot-scope="scope">
            <el-button type="default" @click="checkDetails(scope.row.id)"
              >查看轨迹</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="申报详细" width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="
                goToSeenOrderInfoPage(
                  scope.row.name,
                  scope.row.idCard,
                  scope.row.enterpriseId,
                  scope.row.enterpriseName,
                  scope.row.type
                )
              "
              >查看详细</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40, 50, 100, 500, 1000, 2000]"
        :current-page="currentPage"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>

    <el-dialog title="参保轨迹" :visible.sync="showInfo">
      <el-table :data="traceData" ref="OrderFeedbackTable">
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="enterpriseName"
          label="单位名称"
        ></el-table-column>
        <el-table-column prop="month" label="生效月份"></el-table-column>
        <el-table-column prop="district" label="参保地区"></el-table-column>
        <el-table-column prop="customer" label="所属客户"></el-table-column>
        <el-table-column prop="branch" label="网点名称"></el-table-column>
        <el-table-column prop="isPositive" label="是否参保">
          <template slot-scope="scope">
            <span v-if="scope.row.isPositive">参保</span>
            <span v-else>停保</span>
          </template>
        </el-table-column>
        <el-table-column prop="creationTime" label="参保时间"></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="导入数据"
      :visible.sync="ImportDataDialog"
      width="500px"
      :before-close="handleExcelClose"
    >
      <el-form ref="ImportDataForm" label-width="100px">
        <el-form-item label="Excel文件：" prop="failDescribe">
          <el-button type="text" @click="getTmplExcel">下载模板</el-button>
          <el-upload
            class="upload-demo"
            action="/"
            :limit="1"
            :on-remove="handleRemove"
            :auto-upload="false"
            :on-change="onChangeFile"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传文件</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传.xls|.xlsx格式文件
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleExcelClose">取 消</el-button>
        <el-button
          type="primary"
          @click="uploadExcelSubmit()"
          :loading="importBtnSubmitLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddNetwork',
  components: {},
  data: function () {
    return {
      fileList: [],
      queryLoading: true,
      importBtnSubmitLoading: false,
      ImportDataDialog: false,
      filters: {
        name: '',
        idcard: '',
        enterpriseName: '',
        cutomer: '',
        branch: '',
        effectiveMonth: '',
        isPositive: '',
        isEntrust: '1',
        isSo: '',
        source: ''
      },
      importShow: false,
      exportShow: false,
      exportDis: false,
      traceData: [],
      showInfo: false,
      insuredEmployeeTableData: [],
      totalCount: 0,
      pageSize: 10,
      currentPage: 1
    }
  },
  methods: {
    handleExcelClose () {
      this.fileList = []
      this.ImportDataDialog = false
    },
    handleExceed () {
      this.$message.warning(`一次只能选择单个文件上传`)
    },
    handleRemove () {
      this.fileList = []
    },
    async getTmplExcel () {
      let res = await this.$api.insured.getTmplExcel()
      if (!res) {
        return false
      }
      const blob = new Blob([res])
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = '参保人员名册导入模板.xlsx' //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    },
    onChangeFile (file, fileList) {
      let f = file.name.split('.').pop()
      if (f != 'xls' && f != 'xlsx') {
        fileList = []
        this.fileList = []
        this.$message({
          message: '请上传 .xls|.xlsx 格式的文件',
          type: 'error'
        })
      } else {
        this.fileList = fileList.length > 0 ? [file.raw] : []
      }
    },
    uploadExcelSubmit () {
      if (this.fileList[0] == null) {
        this.$message.error(`请选择文件上传!`)
        return
      }
      let formData = new FormData()
      formData.append('file', this.fileList[0])
      this.importBtnSubmitLoading = true
      this.$api.insured
        .uploadExcel(formData)
        .then(res => {
          console.log(res)
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '导入成功!'
            })
            this.handleExcelClose()
            this.searchList()
          } else {
            this.$message.error(`导入失败!`)
            let errMsg = res.msg + '\r\n\r\n'
            for (var i = 0; i < res.failList.length; i++) {
              errMsg +=
                res.failList[i].number +
                '_' +
                res.failList[i].name +
                '_' +
                res.failList[i].idCard +
                '_' +
                res.failList[i].enterpriseName +
                '_' +
                res.failList[i].typeDesc +
                ':\r\n' +
                res.failList[i].msg +
                '\r\n\r\n'
            }
            this.$msgTotxt(errMsg, '参保人员导入失败原因.txt')
          }
          this.importBtnSubmitLoading = false
        })
        .catch(e => {
          this.importBtnSubmitLoading = false
          this.$message.error('处理失败,发生异常')
          this.$msgTotxt(e.data.msg, '参保人员导入异常原因.txt')
        })
    },
    handleSizeChange (pageSize) {
      // 每页条数改变
      this.pageSize = pageSize
      this.getList()
    },
    handleCurrentChange (currentPage) {
      // 当前页码改变
      this.currentPage = currentPage
      this.getList()
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.searchList()
    },
    async checkDetails (id) {
      try {
        let data = await this.$api.insured.info(id)
        this.traceData = data.data
        this.showInfo = true
      } catch (err) {
        console.log(err)
        this.$msgTotxt(err.data.msg, '错误日志.txt')
      }
    },
    searchList () {
      this.currentPage = 1
      this.pageSize = 10
      this.getList()
    },
    updateTableData () {
      this.currentPage = 1
      this.pageSize = 10
      this.$refs['filtersform'].resetFields()
      this.getList()
    },
    async getList () {
      let listParams = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        idCard: this.filters.idCard,
        name: this.filters.name,
        enterpriseName: this.filters.enterpriseName,
        branch: this.filters.branch,
        customer: this.filters.customer,
        effectiveMonth: this.filters.effectiveMonth,
        isPositive: this.filters.isPositive,
        isEntrust:
          this.filters.isEntrust == null || this.filters.isEntrust == ''
            ? -1
            : this.filters.isEntrust,
        isSo:
          this.filters.isSo == null || this.filters.isSo == ''
            ? -1
            : this.filters.isSo,
        source:
          this.filters.source == null || this.filters.source === ''
            ? -1
            : this.filters.source //如果带的参数为空或者null，那么带参数-1；其他值为原始值
      }
      try {
        this.queryLoading = true
        let listData = await this.$api.insured.list(listParams)
        this.totalCount = listData.data.totalCount
        this.insuredEmployeeTableData = listData.data.item
        this.queryLoading = false
      } catch (err) {
        console.log(err)
        this.queryLoading = false
      }
      console.log('en', this.filters.source)
    },
    goToSeenOrderInfoPage (name, idCard, enterpriseId, enterpriseName, type) {
      debugger
      this.$router.push({
        name: 'OrderInfo',
        query: {
          name: name,
          idCard: idCard,
          enterpriseId: enterpriseId,
          enterpriseName: enterpriseName,
          type: type,
          timestamp: new Date().getTime(), // 添加时间戳
        }
      })
    },
    async IsOperable (pid, userid) {
      try {
        let res = await this.$api.authority.IsOperable(pid, userid)
        if (pid == 'OP100359') this.importShow = res
        if (pid == 'OP100483') this.exportShow = res
      } catch (e) {
        console.log(e)
        this.$message.error('获取按钮权限失败！')
      }
    },
    getBtnAuth () {
      // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
      let userId = this.$cookies.get('userId')
      if (userId == null || userId == '')
        userId = 'e92983fd6e4c4f598b1e7dfb9d6365d1'

      this.IsOperable('OP100359', userId)
      this.IsOperable('OP100483', userId)
    },
    indexMethod (index) {
      //实现表格序号自增
      let curpage = this.currentPage
      let limitpage = this.pageSize
      return index + 1 + (curpage - 1) * limitpage
    },
    exportData () {
      this.$confirm('确认导出下面表中的所有数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let listParams = {
            pageCount: this.currentPage,
            takeCount: this.pageSize,
            idCard: this.filters.idCard,
            name: this.filters.name,
            enterpriseName: this.filters.enterpriseName,
            branch: this.filters.branch,
            customer: this.filters.customer,
            effectiveMonth: this.filters.effectiveMonth,
            isPositive: this.filters.isPositive,
            isEntrust:
              this.filters.isEntrust == null || this.filters.isEntrust == ''
                ? -1
                : this.filters.isEntrust,
            isSo:
              this.filters.isSo == null || this.filters.isSo == ''
                ? -1
                : this.filters.isSo,
            source:
              this.filters.source == null || this.filters.source === ''
                ? -1
                : this.filters.source
          }
          this.exportDis = true
          this.$api.insured
            .downloadExcel(listParams)
            .then(response => {
              let fileName =
                '小库托管-在保员工名册导出' +
                new Date().toLocaleString('en-GB') +
                '.xlsx'
              this.$downloadByFileBytes(response, fileName)
              this.exportDis = false
            })
            .catch(() => {
              this.$message.error(
                '操作失败,发生异常,请尝试重新登录,或者联系管理员!'
              )
              this.exportDis = false
            })
        })
        .catch(() => {})
    }
  },
  created () {
    this.getList()
    this.getBtnAuth()
  }
}
</script>

<style src="@/assets/public.css"></style>
<style scoped>
#InsuredEmployee {
  padding: 20px;
}

#InsuredEmployee .operation-pannel {
  padding-top: 0;
}

.mailDetailInfo .eachMailInfo {
  border-bottom: 1px solid #ededed;
}

.eachMailInfo + .eachMailInfo {
  margin-top: 30px;
}

.mail-info {
  line-height: 20px;
  margin-bottom: 16px;
}

.mailDetailInfo {
  max-height: 500px;
  overflow-y: auto;
}

.delUploadFileIcon {
  margin-left: 16px;
}

.uploadTips {
  color: #888;
}
.responsive-input {
  width: 120%; /* 设置 input 框默认宽度为 100% */
}

/* 添加媒体查询，在小屏幕下调整 input 框的大小 */
@media (max-width: 768px) {
  .responsive-input {
    width: calc(100% - 20px); /* 例子中设置宽度减去 20px 的 padding */
  }
}
</style>
