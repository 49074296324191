<template>
  <div id="BillList">
    <div class="page-header">
      <p class="page-path">账单管理<span class="slashline">/</span>应收台账</p>
      <p class="page-title">台账列表</p>
    </div>
    <div class="operation-pannel">
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row :gutter="16">
          <el-col :span="6">
            <el-form-item label="单位名称：" prop="EnterpriseName">
              <el-input
                v-model="filters.EnterpriseName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="托管网点：" prop="Branch">
              <el-input
                v-model="filters.Branch"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="账单状态：" prop="Status">
              <el-select
                v-model="filters.Status"
                clearable
                placeholder="请选择"
              >
                <el-option label="待提交" value="0"></el-option>
                <el-option label="待审核" value="1"></el-option>
                <el-option label="审核拒绝" value="2"></el-option>
                <el-option label="待推送" value="3"></el-option>
                <el-option label="已推送" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="searchData">查询</el-button>
            <el-button
              type="default"
              @click="
                resetFilter();
                searchData();
              "
              >重置</el-button
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="台账月份：" prop="Month">
              <el-date-picker
                :editable="true"
                v-model="filters.Month"
                value-format="yyyy-MM"
                type="month"
                placeholder="请选择"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="台账类型：" prop="BillType">
              <el-select
                v-model="filters.BillType"
                placeholder="请选择"
                clearable
              >
                <el-option label="社保台账" :value="0"></el-option>
                <el-option label="公积金台账" :value="1"></el-option>
                <el-option label="服务费台账" :value="2"></el-option>
                <el-option label="开户台账" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="op-row">
        <el-button type="primary" @click="importBill" v-show="importBillShow"
          >导入台账</el-button
        >
        <el-button
          @click="submitAudit"
          type="primary"
          plain
          v-show="submitAuditShow"
          >提交审核</el-button
        >
        <el-button
          @click="aprovedAudit"
          type="primary"
          plain
          v-show="aprovedAuditShow"
          >审核通过</el-button
        >
        <el-button
          @click="failedAudit"
          type="danger"
          plain
          v-show="failedAuditShow"
          >审核拒绝</el-button
        >
        <el-button @click="postBill" type="primary" plain v-show="postBillShow"
          >推送账单</el-button
        >
        <el-button @click="withdrawAudit" v-show="withdrawAuditShow"
          >撤回账单</el-button
        >
        <el-button
          @click="paySuccess"
          type="primary"
          plain
          v-show="paySuccessShow"
          >缴费成功</el-button
        >
        <el-button @click="payFailed" type="danger" plain v-show="payFailedShow"
          >缴费失败</el-button
        >
        <el-button
          @click="deleteBill"
          type="danger"
          plain
          v-show="deleteBillShow"
          >删除账单</el-button
        >
        <el-button @click="billDetail" v-show="true">查看详情</el-button>
        <el-button @click="exportExcel" type="primary" plain v-show="true"
          >导出账单</el-button
        >
      </div>
    </div>
    <div class="table-container">
      <el-table
        max-height="500"
        :data="tableData"
        ref="tableData"
        border
        style="width: 100%; margin-bottom: 30px"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="50"
        >
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">待提交</span>
            <span v-else-if="scope.row.status == 1">待审核</span>
            <span v-else-if="scope.row.status == 2">审核拒绝</span>
            <span v-else-if="scope.row.status == 3">待推送</span>
            <span v-else-if="scope.row.status == 4">已推送</span>
            <span v-else-if="scope.row.status == -1">正在导入</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="审核意见"></el-table-column>
        <el-table-column prop="billType" label="台账类型">
          <template slot-scope="scope">
            <span v-if="scope.row.billType == 0">社保台账</span>
            <span v-else-if="scope.row.billType == 1">公积金台账</span>
            <span v-else-if="scope.row.billType == 2">服务费台账</span>
            <span v-else-if="scope.row.billType == 3">开户台账</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="缴费状态">
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus == 0">未缴费</span>
            <span v-else-if="scope.row.payStatus == 1">缴费成功</span>
            <span v-else-if="scope.row.payStatus == 2">缴费失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="customer" label="所属客户"></el-table-column>
        <el-table-column prop="enterpriseId" label="单位编号"></el-table-column>
        <el-table-column
          prop="enterpriseName"
          label="单位名称"
        ></el-table-column>
        <el-table-column prop="branch" label="所属网点"></el-table-column>
        <el-table-column prop="city" label="参缴城市"></el-table-column>
        <el-table-column prop="month" label="台账月份">
          <template slot-scope="scope">
            <div>
              {{ commonjs.dateFtt("yyyy-MM", new Date(scope.row.month)) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="台账人数"></el-table-column>
        <el-table-column prop="creationTime" label="导入时间"></el-table-column>
        <el-table-column prop="totalCost" label="账单金额"></el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <OptLog :menuId="menuId" :relationId="scope.row.id" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-size="pageSize"
        :current-page="currentPage"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="导入台账"
      class="popContainer"
      :visible.sync="ImportDataDialog"
      width="500px"
      :before-close="handleExcelClose"
    >
      <el-form :model="ImportDataForm" ref="ImportDataForm" label-width="100px">
        <el-form-item label="单位名称：" prop="EnterpriseName">
          <el-select
            clearable
            v-model="ImportDataForm.EnterpriseName"
            style="width: 220px"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in companyArray"
              :key="index"
              :label="item.lable"
              :value="item"
              @change="typeChange"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="台账月份：" prop="Month">
          <el-date-picker
            :editable="true"
            v-model="ImportDataForm.Month"
            type="month"
            value-format="yyyy-MM"
            placeholder="请选择"
            @change="typeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="台账类型：" prop="BillType">
          <el-select
            v-model="ImportDataForm.BillType"
            placeholder="请选择"
            @change="typeChange"
            clearable
            style="width: 220px"
          >
            <el-option label="社保台账" :value="0"></el-option>
            <el-option label="公积金台账" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件类型：" prop="fileType">
          <el-radio-group @change="changeFileType" v-model="fileType">
            <el-radio label="0">原始台账明细</el-radio>
            <el-radio label="1">其他缴费信息</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="台账人数：" v-show="isOtherFile" prop="Number">
          <el-input style="width: 220px" v-model="number"></el-input>
        </el-form-item>
        <el-form-item
          label="台账总金额："
          v-show="isOtherFile"
          prop="TotalMoney"
        >
          <el-input style="width: 220px" v-model="totalMoney"></el-input>
        </el-form-item>
        <el-form-item label="台账上传：" prop="failDescribe">
          <el-upload
            class="upload-demo"
            action="/"
            :limit="limitFile"
            :on-remove="handleRemove"
            :auto-upload="false"
            :on-change="onChangeFile"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传文件</el-button>
            <div slot="tip" class="el-upload__tip" v-text="tips"></div>
          </el-upload>
        </el-form-item>
        <el-form-item v-if="errTipShow" style="color: red" label="注意：">
          <span>已存在审核通过数据</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleExcelClose">取 消</el-button>
        <el-button
          type="primary"
          @click="uploadExcelSubmit(ImportDataForm)"
          :loading="importBtnSubmitLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="审核拒绝"
      width="40%"
      :visible.sync="auditFailVisible"
      @close="auditFailCancel"
    >
      <el-form ref="form" label-width="20%;">
        <el-form-item label="拒绝原因:" required>
          <el-col :span="11">
            <el-input type="textarea" v-model="failReason"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="auditFailSubmit"
            style="margin-left: 26%"
            >确认</el-button
          >
          <el-button @click="auditFailCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// import Cookie from "js.cookie";
export default {
  name: "BillList",
  components: {},
  data: function () {
    return {
      menuId: "",
      Id: "",
      tableData: [],
      filters: {
        EnterpriseName: "",
        BillType: "",
        Branch: "",
        Status: "",
        Month: "",
      },
      fileType: "0", //文件类型
      limitFile: 1, //允许上传最大文件数
      isOtherFile: false, //是否是其他文件，控制台账总人数 台账总金额显示
      tips: "只能上传.xls|.xlsx格式文件",
      number: 0, //台账人数
      totalMoney: 0, //台账金额
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      tableLoading: false,
      addShow: false,
      auditShow: false,
      failReason: "",
      auditFailVisible: false,
      auditoptions: [
        {
          value: "0",
          label: "待提交",
        },
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "审核拒绝",
        },
        {
          value: "3",
          label: "待推送",
        },
        {
          value: "4",
          label: "已推送",
        },
      ],
      importBillShow: false,
      submitAuditShow: false,
      withdrawAuditShow: false,
      aprovedAuditShow: false,
      failedAuditShow: false,
      postBillShow: false,
      paySuccessShow: false,
      payFailedShow: false,
      deleteBillShow: false,
      companyArray: [],
      ImportDataForm: {
        EnterpriseName: "",
        Month: "",
        BillType: null,
      },
      fileList: [],
      ImportDataDialog: false,
      importBtnSubmitLoading: false,
      errTipShow: false,
    };
  },
  async created() {
  try {
    let menuId = await this.$api.publicapi.getMenuId("台账管理-应收账单管理");
    console.log("menuId 获取成功:", menuId);
    
    // 确保 menuId 是一个有效的值
    if (menuId && typeof menuId === "object") {
      console.warn("menuId 可能是对象，检查是否正确解析:", menuId);
    }

    this.menuId = menuId;
  } catch (error) {
    console.error("获取 menuId 失败:", error);
    this.menuId = null; // 赋值一个默认值，避免后续报错
  }
},
  methods: {
    //重置查询条件
    resetFilter() {
      this.filters = {};
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getList();
    },
    //导入时，填写台账信息判断
    async typeChange() {
      this.errTipShow = false;
      if (
        this.ImportDataForm.EnterpriseName.lable != null &&
        this.ImportDataForm.Month != null &&
        this.ImportDataForm.Month != "" &&
        this.ImportDataForm.BillType != null
      ) {
        let params = {
          EnterpriseName: this.ImportDataForm.EnterpriseName.lable,
          EnterpriseId: this.ImportDataForm.EnterpriseName.value,
          Month: this.ImportDataForm.Month,
          BillType: this.ImportDataForm.BillType,
        };
        try {
          console.log(params);
          let res = await this.$api.SaleBill.IsImported(params);
          let typeName = "";
          if (this.ImportDataForm.BillType == 0) {
            typeName = "社保账单";
          } else {
            typeName = "公积金账单";
          }
          console.log(res);
          if (res) {
            if (res.data) {
              this.$message.error(
                "单位:" +
                  params.EnterpriseName +
                  "已存在台账月份为" +
                  params.Month +
                  "的审核通过的" +
                  typeName +
                  "数据"
              );
              this.errTipShow = true;
            } else {
              return;
            }
          } else {
            this.$message.error("查询单位导入台账信息出错");
          }
        } catch (e) {
          console.log(e);
          this.$message.error(e.message);
        }
      }
    },
    //导入时，填写台账信息判断
    tyChange() {
      if (
        this.ImportDataForm.EnterpriseName == null ||
        this.ImportDataForm.EnterpriseName == ""
      ) {
        this.$message.error(`请先选择单位名称!`);
        this.ImportDataForm.BillType = "";
        return;
      } else if (
        this.ImportDataForm.Month == null ||
        this.ImportDataForm.Month == ""
      ) {
        this.$message.error(`请先选择台账月份!`);
        this.ImportDataForm.BillType = "";
        return;
      } else {
        return;
      }
    },
    //导入台账
    importBill() {
      //导入界面
      this.ImportDataDialog = true;
      if (this.companyArray.length == 0) {
        this.$api.publicapi
          .enterprises()
          .then((res) => {
            this.companyArray = res;
          })
          .catch(() => {});
      }
    },
    exportExcel() {
      let selection = this.$refs.tableData.selection;
      let ids = [];
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要导出的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection.length > 1) {
        for (let item of selection) {
          if (
            item.billType == "0" ||
            item.billType == "1" ||
            item.billType == "2"
          ) {
            this.$message({
              message: "暂不支持社保、公积金账单数据导出及服务费账单批量导出！",
              type: "warning",
              showClose: true,
            });
            return;
          }
          ids.push(item.id);
        }
      } else {
        if (selection[0].billType == "0" || selection[0].billType == "1") {
          this.$message({
            message: "暂不支持社保、公积金账单数据导出！",
            type: "warning",
            showClose: true,
          });
          return;
        }
        ids.push(selection[0].id);
      }
      this.$confirm("确认导出选中的账单数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            ids: ids,
            BillType: selection[0].billType,
          };
          this.$api.SaleBill.exportBill(params).then(
            (response) => {
              let fileName =
                "应收台账导出" + new Date().toLocaleString("en-GB") + ".zip";
              this.$downloadByFileBytes(response, fileName);
            },
            (res) => {
              console.log(res);
              if (res.code == 500) {
                //this.$message.error(res.msg);
                this.$message.error("导出失败！");
                return;
              }
            }
          );
        })
        .catch();
    },
    //台账详情
    billDetail() {
      //根据类型查找对应的明细，加载不同界面
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection[0].billType == "0") {
        this.$router.push({
          name: "SocialBillDetail",
          params: {
            bid: selection[0].id,
          },
        });
      } else if (selection[0].billType == "1") {
        this.$router.push({
          name: "AccBillDetail",
          params: {
            bid: selection[0].id,
          },
        });
      } else if (selection[0].billType == "2") {
        this.$router.push({
          name: "ServiceBillDetail",
          params: {
            bid: selection[0].id,
          },
        });
      } else if (selection[0].billType == "3") {
        this.$router.push({
          name: "OpenBillDetail",
          params: {
            bid: selection[0].id,
          },
        });
      }
    },
    //1、提交审核 2、审核拒绝 3、审核通过 4、撤回账单 5、推送账单
    //提交审核 待提交->待审核
    submitAudit() {
      // 提交审核
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条“待提交”状态需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 0) {
          console.log(item);
          this.$message({
            message: "请选择“待提交”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("你确定要提交吗?", "提交审核提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.BillAudit(selection[0].id, selection[0].billType, 1, "待审核");
        })
        .catch(() => {});
    },
    //审核通过 待审核->待推送
    aprovedAudit() {
      // 审核通过
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 1) {
          this.$message({
            message: "请选择“待审核”审核状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("你确定要通过审核吗?", "审核通过提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.BillAudit(selection[0].id, selection[0].billType, 3, "审核通过");
        })
        .catch(() => {});
    },
    //撤销审核 待审核->待提交
    withdrawAudit() {
      // 撤销审核
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 1) {
          this.$message({
            message: "请选择“待审核”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("你确定要撤回吗?", "撤回提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.BillAudit(selection[0].id, selection[0].billType, 4, "撤回账单");
        })
        .catch(() => {});
    },
    //审核拒绝 待审核 -> 审核拒绝
    failedAudit() {
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 1) {
          this.$message({
            message: "请选择“待审核”审核状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.auditFailVisible = true;
    },
    //审核拒绝提交
    auditFailSubmit() {
      let selection = this.$refs.tableData.selection;
      this.BillAudit(
        selection[0].id,
        selection[0].billType,
        2,
        this.failReason
      );
    },
    auditFailCancel() {
      this.auditFailVisible = false;
    },
    async BillAudit(id, billType, flag, failReason) {
      try {
        let res = await this.$api.SaleBill.BillAudit(
          id,
          billType,
          flag,
          failReason
        );
        if (res) {
          if (flag == 1) {
            this.$message({
              type: "success",
              message: "账单提交成功!",
            });
          }
          if (flag == 2) {
            this.$message({
              type: "success",
              message: "账单审核拒绝成功!",
            });
          }
          if (flag == 3) {
            this.$message({
              type: "success",
              message: "账单审核通过!",
            });
          }
          if (flag == 4) {
            this.$message({
              type: "success",
              message: "账单撤回成功!",
            });
          }
          if (flag == 5) {
            this.$message({
              type: "success",
              message: "账单推送成功!",
            });
          }
          (this.auditFailVisible = false), (this.failReason = "");
          this.getList();
        } else {
          this.$message.error("操作失败！");
        }
      } catch (e) {
        console.log(e);
        this.$message.error(e.data.msg);
      }
    },
    //推送账单 待推送->已推送
    postBill() {
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 3) {
          this.$message({
            message: "请选择“待推送”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("你确定要推送账单吗?", "推送账单提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.BillAudit(selection[0].id, selection[0].billType, 5, "账单推送");
        })
        .catch(() => {});
    },
    deleteBill() {
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要删除的账单数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要删除的账单数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 0 || item.billType > 1) {
          console.log(item);
          this.$message({
            message:
              "请选择“待提交”状态的【社保】或【公积金】账单数据进行删除操作",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("您确定要删除该条账单数据吗?", "删除账单提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.BillAudit(selection[0].id, selection[0].billType, 6, "删除账单");
        })
        .catch(() => {});
    },
    //缴费成功 已推送 且 未缴费 状态
    paySuccess() {
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 4 || item.payStatus != 0) {
          this.$message({
            message: "请选择“已推送”且“未缴费”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("你确定该账单缴费成功吗?", "缴费成功提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.BillPay(selection[0].id, selection[0].billType, 1);
        })
        .catch(() => {});
    },
    //缴费失败 已推送 且 未缴费 状态
    payFailed() {
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      for (let item of selection) {
        if (item.status != 4 || item.payStatus != 0) {
          this.$message({
            message: "请选择“已推送”且“未缴费”状态的数据进行处理",
            type: "error",
            showClose: true,
          });
          return;
        }
      }
      this.$confirm("你确定该账单缴费失败吗?", "缴费失败提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.BillPay(selection[0].id, selection[0].billType, 2);
        })
        .catch(() => {});
    },
    async BillPay(id, billType, flag) {
      try {
        let res = await this.$api.SaleBill.BillPay(id, billType, flag);
        if (res) {
          if (flag == 1) {
            this.$message({
              type: "success",
              message: "账单变更缴费状态为缴费成功!",
            });
          }
          if (flag == 2) {
            this.$message({
              type: "success",
              message: "账单变更缴费状态缴费失败!",
            });
          }
          (this.auditFailVisible = false), (this.failReason = "");
          this.getList();
        } else {
          this.$message.error("操作失败！");
        }
      } catch (e) {
        console.log(e);
        this.$message.error(e.msg);
      }
    },
    indexMethod(index) {
      //实现表格序号自增
      let curpage = this.currentPage;
      let limitpage = this.pageSize;
      return index + 1 + (curpage - 1) * limitpage;
    },
    //查询
    searchData() {
      this.pageSize = 10;
      this.currentPage = 1;
      this.totalCount = 0;
      this.getList();
    },
    async getList() {
      let params = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        //bid: this.Id,
        EnterpriseName: this.filters.EnterpriseName,
        Branch: this.filters.Branch,
        Status: this.filters.Status,
        Month: this.filters.Month,
        BillType: this.filters.BillType,
      };
      this.tableLoading = true;
      try {
        console.log(params);
        let res = await this.$api.SaleBill.SaleBillList(params);
        if (res) {
          this.totalCount = res.totalCount;
          this.tableData = res.item;
        } else {
          this.$message.error("查询出错");
        }
        this.tableLoading = false;
      } catch (e) {
        console.log(e);
        this.tableLoading = false;
      }
    },
    //取消上传
    handleExcelClose() {
      this.fileList = [];
      this.$refs["ImportDataForm"].resetFields();
      this.ImportDataDialog = false;
      this.errTipShow = false;
    },
    // ImportDataFormRules: {
    //     EnterpriseName: [
    //       { required: true, message: "请选择单位名称", trigger: "change" },
    //     ],
    //     Month: [
    //       { required: true, message: "请选择台账月份", trigger: "change" },
    //     ],
    //     BillType:[
    //       { required: true, message: "请选择台账类型", trigger: "change" },
    //     ]
    //   },
    handleExceed() {
      if (this.isOtherFile) {
        this.$message.warning(
          `最多只能选择10个文件上传，超过数量请上传压缩后再上传压缩包`
        );
      } else {
        this.$message.warning(`一次只能选择单个文件上传`);
      }
    },
    handleRemove() {
      this.fileList = [];
    },
    uploadExcelSubmit() {
      if (
        this.ImportDataForm.EnterpriseName == null ||
        this.ImportDataForm.EnterpriseName == ""
      ) {
        this.$message.error(`请选择单位名称!`);
        return;
      }
      if (
        this.ImportDataForm.Month == null ||
        this.ImportDataForm.Month == ""
      ) {
        this.$message.error(`请选择台账月份!`);
        return;
      }
      if (
        (this.ImportDataForm.BillType == null ||
          this.ImportDataForm.BillType == "") &&
        this.ImportDataForm.BillType != "0"
      ) {
        this.$message.error(`请选择台账类型!`);
        return;
      }
      if (this.fileList[0] == null) {
        this.$message.error(`请选择文件上传!`);
        return;
      }
      if (this.isOtherFile) {
        if (this.number == 0 || this.number == null) {
          this.$message.error(`请填写台账人数!`);
          return;
        } else {
          let reg = /^[0-9]*$/;
          if (!reg.test(this.number)) {
            this.$message.error(`台账人数必须为大于0的整数!`);
            return;
          }
        }

        if (this.totalMoney == 0 || this.totalMoney == null) {
          this.$message.error(`请填写台账总金额!`);
          return;
        } else {
          let reg = /^(-|\+)?\d+(\.\d+)?$/;
          if (!reg.test(this.totalMoney)) {
            this.$message.error(`台账金额必须为数字!`);
            return;
          }
        }
      }
      let formData = new FormData();
      this.fileList.forEach((element) => {
        formData.append("file", element.raw);
      });
      formData.append(
        "EnterpriseName",
        this.ImportDataForm.EnterpriseName.lable
      );
      formData.append("EnterpriseId", this.ImportDataForm.EnterpriseName.value);
      formData.append("Month", this.ImportDataForm.Month);
      formData.append("BillType", this.ImportDataForm.BillType);
      formData.append("Number", this.number);
      formData.append("TotalMoney", this.totalMoney);
      formData.append("isOtherFile", this.fileType);
      this.importBtnSubmitLoading = true;
      console.log(formData);
      this.$api.SaleBill.uploadExcel(formData)
        .then((res) => {
          if (res.code != 200) {
            console.log(res);
            this.$message.error(`处理失败!`);
            let text = res.msg + "\r\n\r\n";
            if (res.failList != null) {
              res.failList.forEach((e) => {
                text += e.name + "_" + e.idCard + "：" + e.remark + "\r\n";
              });
            }
            this.handleExcelClose();
            this.ImportDataDialog = false;
            this.resetFilter();
            this.searchData();
            this.$msgTotxt(text, "导入处理失败原因.txt");
          } else {
            this.$message({
              type: "success",
              message: "台账导入成功!",
            });
            this.handleExcelClose();
            this.ImportDataDialog = false;
            this.resetFilter();
            this.searchData();
          }
          this.importBtnSubmitLoading = false;
        })
        .catch((e) => {
          this.importBtnSubmitLoading = false;
          // this.fileList = [];
          // this.fileType = '0';
          // this.isOtherFile = false;
          this.$message.error("处理失败,发生异常");
          this.$msgTotxt(e.data.msg, "导入处理异常原因.txt");
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeFileType(val) {
      //清空文件
      this.fileList = [];
      if (val == 0) {
        this.limitFile = 1;
        this.isOtherFile = false;
        this.tips = "只能上传.xls|.xlsx格式文件";
        this.totalMoney = 0;
        this.number = 0;
      } else {
        this.totalMoney = 0;
        this.number = 0;
        this.limitFile = 10;
        this.isOtherFile = true;
        this.tips = "只能上传.xls|.xlsx|.png|.jpg|.pdf|.7z|.rar|.zip格式文件";
      }
    },
    onChangeFile(file, fileList) {
      let f = file.name.split(".").pop();
      if (!this.isOtherFile && f != "xls" && f != "xlsx") {
        fileList = [];
        this.fileList = [];
        this.$message({
          message: "请上传.xls|.xlsx 格式文件",
          type: "error",
        });
      } else if (
        this.isOtherFile &&
        f != "xls" &&
        f != "xlsx" &&
        f != "png" &&
        f != "jpg" &&
        f != "pdf" &&
        f != "7z" &&
        f != "rar" &&
        f != "zip"
      ) {
        fileList.splice(0, fileList.length - 2);
        this.fileList.splice(0, fileList.length - 2);
        this.$message({
          message: "请上传.xls|.xlsx|.png|.jpg|.pdf|.7z|.rar|.zip格式文件",
          type: "error",
        });
      } else {
        this.fileList = fileList.length > 0 ? fileList : [];
      }
    },
    async IsOperable(pid, userid) {
      try {
        let res = await this.$api.authority.IsOperable(pid, userid);
        if (pid == "OP100362") {
          this.importBillShow = res;
        }
        if (pid == "OP100363") {
          this.submitAuditShow = res;
        }
        if (pid == "OP100364") {
          this.withdrawAuditShow = res;
        }
        if (pid == "OP100365") {
          this.aprovedAuditShow = res;
        }
        if (pid == "OP100366") {
          this.failedAuditShow = res;
        }
        if (pid == "OP100367") {
          this.postBillShow = res;
        }
        if (pid == "OP100368") {
          this.paySuccessShow = res;
        }
        if (pid == "OP100369") {
          this.payFailedShow = res;
        }
        if (pid == "OP100372") {
          this.deleteBillShow = res;
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取按钮权限失败！");
      }
    },
    getBtnAuth() {
      // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
      let userId = this.$cookies.get("userId");
      if (userId == null || userId == "")
        userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

      this.IsOperable("OP100362", userId);
      this.IsOperable("OP100363", userId);
      this.IsOperable("OP100364", userId);
      this.IsOperable("OP100365", userId);
      this.IsOperable("OP100366", userId);
      this.IsOperable("OP100367", userId);
      this.IsOperable("OP100368", userId);
      this.IsOperable("OP100369", userId);
      this.IsOperable("OP100372", userId);
    },
  },
  mounted() {
    this.getList();
    this.getBtnAuth();
  },
};
</script>

<style src="@/assets/public.css"></style>
<style scoped>
#BillList {
  padding: 20px;
}

.op-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.row-input {
  width: 240px;
  margin: 0 20px 0 10px;
}

.table-container {
  background: #fff;
  padding: 0 20px 20px;
}

.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
}
</style>
