<template>
    <div id="ApplyFieldOperation">
        <div class="page-header">
            <p class="page-path">
                申报设置<span class="slashline">/</span>申报字段设置
            </p>
            <p class="page-title">{{operationTitle}}</p>
        </div>
        <div class="operation-pannel"><br/>
            <el-form ref="operationForm" :model="operationForm" :rules="operationRules" label-width="85px">
                <el-row :gutter="16">
                    <el-col :offset="1">
                        <el-form-item label="生效时间:" prop="useTime">
                            <el-date-picker v-model="operationForm.useTime" type="date" format="yyyy/MM/dd" value-format="yyyy/MM/dd" placeholder="请选择"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :offset="1">
                        <el-form-item label="适用地区:" prop="addRegion">
                            <el-button @click="addRegion">添加地区</el-button><br/>
                            <el-input type="textarea" :rows="3" placeholder="请添加适用地区" v-model="operationForm.addRegion" :readonly="true" @blur="addRegionChange" style="width:30%;color:#FFFAFA !important;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :offset="1">
                        <el-form-item label="业务类型:" prop="operationType">
                            <el-select v-model="operationForm.operationType" :disabled="true" placeholder="请选择业务类型">
                                <el-option label="社保" :value="0"></el-option>
                                <el-option label="公积金" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="table-container">
                <div style="background: #fafafa;border: 1px solid #ededed;height:48px;display: flex;align-items: center;">
                    <span v-if="this.type == 0">申报字段设置</span>
                    <span v-if="this.type == 1">申报资料设置</span>
                    <el-button @click="addField" type="primary" style="margin-left:20px;">新增</el-button>
                </div>
                <el-form ref="fieldListForm" :model="fieldListForm">
                    <el-table
                        ref="fieldListTable"
                        max-height="350"
                        :data="fieldListForm.fieldList"
                        border
                        style="width: 100%; margin-bottom: 30px"
                        v-loading="tableLoading">"
                        <el-table-column prop="id" label="id" v-if="show" key="1"></el-table-column>
                        <el-table-column prop="applyFieldId" label="主表id" v-if="show" key="2"></el-table-column>
                        <el-table-column prop="applyType" label="申报类型" key="3">
                            <template slot-scope="scope">
                                <span v-if="scope.row.applyType == 0">社保新增</span>
                                <span v-else-if="scope.row.applyType == 1">社保续保</span>
                                <span v-if="scope.row.applyType == 2">社保停保</span>
                                <span v-else-if="scope.row.applyType == 3">社保变更</span>
                                <span v-if="scope.row.applyType == 4">社保补缴</span>
                                <span v-else-if="scope.row.applyType == 5">社保退保</span>
                                <span v-if="scope.row.applyType == 6">公积金新增</span>
                                <span v-else-if="scope.row.applyType == 7">公积金调入</span>
                                <span v-if="scope.row.applyType == 8">公积金封存</span>
                                <span v-else-if="scope.row.applyType == 9">公积金变更</span>
                                <span v-if="scope.row.applyType == 10">公积金补缴</span>
                                <span v-else-if="scope.row.applyType == 11">公积金退保</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldName" label="字段名称" v-if="this.type == 0" key="4"></el-table-column>
                        <el-table-column prop="fieldName" label="资料名称" v-if="this.type == 1" key="4"></el-table-column>
                        <el-table-column prop="fieldType" label="字段类型" v-if="this.type == 0" key="5">
                            <template slot-scope="scope">
                                <span v-if="scope.row.fieldType == 0">文本类</span>
                                <span v-else-if="scope.row.fieldType == 1">下拉选择类</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldType" label="资料类型" v-if="this.type == 1" key="5">
                            <template slot-scope="scope">
                                <span v-if="scope.row.fieldType == 0">原件类</span>
                                <span v-else-if="scope.row.fieldType == 1">图像类</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="isRequired" v-if="true" label="是否必填" key="6">
                            <template slot-scope="scope">
                                <span v-if="scope.row.isRequired == 0">否</span>
                                <span v-else-if="scope.row.isRequired == 1">是</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldContent" label="字段内容" show-overflow-tooltip v-if="this.type == 0" key="7"></el-table-column>  
                        <el-table-column prop="fieldContent" label="资料要求" show-overflow-tooltip v-if="this.type == 1" key="7"></el-table-column>
                        <el-table-column prop="count" label="资料份数" v-if="this.type == 1" key="8"></el-table-column>
                        <el-table-column prop="fileType" label="文件格式" v-if="this.type == 1" key="9"></el-table-column>
                        <el-table-column prop="dataSampleUrl" label="资料样本" v-if="this.type == 1" key="10">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="downLoad(scope.row.dataSampleUrl)" v-if="scope.row.dataSampleUrl != null && scope.row.dataSampleUrl != ''">下载</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="emptyListUrl" label="空表上传" v-if="this.type == 1" key="11">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="downLoad(scope.row.emptyListUrl)" v-if="scope.row.emptyListUrl != null && scope.row.emptyListUrl != ''">下载</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldCode" label="字段/资料编码Code" v-if="show" key="12"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                                <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
        </div>
        <div style="text-align:center"><br/>
            <el-button @click="confirmList" type="primary">确定</el-button>
            <el-button @click="returnList" >返回</el-button>
        </div>

        <el-dialog :title="operationDiaTitle+'申报资料'" class="popContainer" :visible.sync="operationFieldDataDialog" width="560px" :before-close="operationFieldDataClose">
            <hr/>
            <el-form ref="addDataForm" :model="addDataForm" :rules="addDataRules" ><br/>
                <el-form-item label="申报类型:" prop="applyType"  v-if="this.operationDiaTitle == '编辑'">
                        <div v-if="this.operationForm.operationType == 0">
                            <el-select v-model="addDataForm.applyType" :disabled="true" placeholder="请选择" style="width:30%;">
                                <el-option label="社保新增" :value="0"></el-option>
                                <el-option label="社保续保" :value="1"></el-option>
                                <el-option label="社保停保" :value="2"></el-option>
                                <el-option label="社保变更" :value="3"></el-option>
                                <el-option label="社保补缴" :value="4"></el-option>
                                <el-option label="社保退保" :value="5"></el-option>
                            </el-select>
                        </div>
                        <div v-if="this.operationForm.operationType == 1">
                            <el-select v-model="addDataForm.applyType" :disabled="true" placeholder="请选择" style="width:30%;">
                                <el-option label="公积金新增" :value="6"></el-option>
                                <el-option label="公积金调入" :value="7"></el-option>
                                <el-option label="公积金封存" :value="8"></el-option>
                                <el-option label="公积金变更" :value="9"></el-option>
                                <el-option label="公积金补缴" :value="10"></el-option>
                                <el-option label="公积金退保" :value="11"></el-option>
                            </el-select>
                        </div>
                </el-form-item>
                <el-form-item label="资料名称:"  prop="fieldName" v-if="this.operationDiaTitle == '编辑'">
                    <el-input v-model="addDataForm.fieldName" :disabled="true" placeholder="请输入资料名称" style="width:30%;"></el-input>
                </el-form-item>

                <el-form-item label="字段/资料编码Code:" v-if="show">
                    <el-input v-model="addDataForm.fieldCode" :disabled="true" style="width:30%;"></el-input>
                </el-form-item>
                
                <el-form-item label="资料类型:" prop="fieldType" v-if="this.operationDiaTitle == '编辑'">
                    <el-select v-model="addDataForm.fieldType" :disabled="true" placeholder="请选择" style="width:30%;">
                        <el-option label="原件类" :value="0"></el-option>
                        <el-option label="图像类" :value="1"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="申报类型:" prop="applyType" v-if="this.operationDiaTitle == '新增'">
                        <div v-if="this.operationForm.operationType == 0">
                            <el-select v-model="addDataForm.applyType" placeholder="请选择" style="width:30%;">
                                <el-option label="社保新增" :value="0"></el-option>
                                <el-option label="社保续保" :value="1"></el-option>
                                <el-option label="社保停保" :value="2"></el-option>
                                <el-option label="社保变更" :value="3"></el-option>
                                <el-option label="社保补缴" :value="4"></el-option>
                                <el-option label="社保退保" :value="5"></el-option>
                            </el-select>
                        </div>
                        <div v-if="this.operationForm.operationType == 1">
                            <el-select v-model="addDataForm.applyType" placeholder="请选择" style="width:30%;">
                                <el-option label="公积金新增" :value="6"></el-option>
                                <el-option label="公积金调入" :value="7"></el-option>
                                <el-option label="公积金封存" :value="8"></el-option>
                                <el-option label="公积金变更" :value="9"></el-option>
                                <el-option label="公积金补缴" :value="10"></el-option>
                                <el-option label="公积金退保" :value="11"></el-option>
                            </el-select>
                        </div>
                </el-form-item>
                <el-form-item label="资料名称:" prop="fieldName" v-if="this.operationDiaTitle == '新增'">
                    <el-input v-model="addDataForm.fieldName" placeholder="请输入资料名称" style="width:30%;"></el-input>
                </el-form-item>
                <el-form-item label="资料类型:" prop="fieldType" v-if="this.operationDiaTitle == '新增'">
                    <el-select v-model="addDataForm.fieldType" placeholder="请选择" style="width:30%;">
                        <el-option label="原件类" :value="0"></el-option>
                        <el-option label="图像类" :value="1"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="资料份数:" prop="count">
                    <el-input v-model="addDataForm.count" placeholder="请输入资料份数" style="width:30%;" oninput="value=value.replace(/\D|^0/g,'')" @blur="countChange"></el-input>
                </el-form-item>
                <el-form-item label="是否必填:" prop="isRequired">
                    <el-radio v-model="addDataForm.isRequired" :label= "1" style="width:12%;">是</el-radio>
                    <el-radio v-model="addDataForm.isRequired" :label= "0" style="width:12%;">否</el-radio>
                </el-form-item>
                <div v-if="addDataForm.fieldType == 0" >
                    <el-form-item label="资料样本:">
                        <el-upload
                            class="upload-demo"
                            action="/"
                            ref="dataUpload"
                            :on-remove="handleDataRemove"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="onChangeDataFile"
                            :on-exceed="handleDataExceed"
                            :file-list="addDataForm.dataSampleUrl">
                            <el-button size="small" type="primary" >点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="width:40%;margin-left:70px;">上传的文件不能超过5m</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="空表上传:">
                        <el-upload
                            class="upload-demo"
                            action="/"
                            ref="emptyUpload"
                            :on-remove="handleEmptyRemove"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="onChangeEmptyFile"
                            :on-exceed="handleEmptyExceed"
                            :file-list="addDataForm.emptyListUrl">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="width:40%;margin-left:70px;">上传的文件不能超过5m</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="资料要求:">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            style="width:70%"
                            v-model="addDataForm.fieldContent">
                        </el-input>
                    </el-form-item>
                </div>
                <div v-if="addDataForm.fieldType == 1">
                    <el-form-item label="资料样本:" required>
                        <el-upload
                            class="upload-demo"
                            action="/"
                            ref="dataUpload"
                            :on-remove="handleDataRemove"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="onChangeDataFile"
                            :on-exceed="handleDataExceed"
                            :file-list="addDataForm.dataSampleUrl">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="width:40%;margin-left:80px;">上传的文件不能超过5m</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="空表上传:" required>
                        <el-upload
                            class="upload-demo"
                            action="/"
                            ref="emptyUpload"
                            :on-remove="handleEmptyRemove"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="onChangeEmptyFile"
                            :on-exceed="handleEmptyExceed"
                            :file-list="addDataForm.emptyListUrl">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip" style="width:40%;margin-left:80px;">上传的文件不能超过5m</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="资料要求:">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            style="width:70%"
                            v-model="addDataForm.fieldContent">
                        </el-input>
                    </el-form-item>
                </div>
            </el-form>
            <hr/><br/>
            <div style="text-align:right;">
                <el-button @click="operationFieldDataConfirm" type="primary">确定</el-button>
                <el-button @click="operationFieldDataClose">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="operationDiaTitle+'申报字段'" class="popContainer" :visible.sync="operationFieldDialog" width="600px"   :before-close="operationFieldClose">
            <hr/>
            <el-form ref="addForm" :model="addForm" :rules="addRules" ><br/>
                <el-form-item label="申报类型:" prop="applyType" v-if="this.operationDiaTitle == '新增'">
                        <div v-if="this.operationForm.operationType == 0">
                            <el-select v-model="addForm.applyType" multiple placeholder="请选择"  style="width:75%;" >
                                <el-option label="社保新增" :value="0"></el-option>
                                <el-option label="社保续保" :value="1"></el-option>
                                <el-option label="社保停保" :value="2"></el-option>
                                <el-option label="社保变更" :value="3"></el-option>
                                <el-option label="社保补缴" :value="4"></el-option>
                                <el-option label="社保退保" :value="5"></el-option>
                            </el-select>
                        </div>
                        <div v-if="this.operationForm.operationType == 1">
                            <el-select v-model="addForm.applyType" multiple placeholder="请选择" style="width:75%;">
                                <el-option label="公积金新增" :value="6"></el-option>
                                <el-option label="公积金调入" :value="7"></el-option>
                                <el-option label="公积金封存" :value="8"></el-option>
                                <el-option label="公积金变更" :value="9"></el-option>
                                <el-option label="公积金补缴" :value="10"></el-option>
                                <el-option label="公积金退保" :value="11"></el-option>
                            </el-select>
                        </div>
                </el-form-item>
                <el-form-item label="字段名称:" prop="fieldName" v-if="this.operationDiaTitle == '新增'">
                    <el-input v-model="addForm.fieldName" placeholder="请输入字段名称" style="width:30%;"></el-input>
                </el-form-item>
                <el-form-item label="字段类型:" prop="fieldType" v-if="this.operationDiaTitle == '新增'">
                    <el-select v-model="addForm.fieldType" placeholder="请选择" style="width:30%;">
                        <el-option label="下拉选择类" :value="1"></el-option>
                        <el-option label="文本类" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申报类型:" prop="applyType" v-if="this.operationDiaTitle == '编辑'">
                        <div v-if="this.operationForm.operationType == 0">
                            <el-select v-model="addForm.applyType" :disabled="true" placeholder="请选择" style="width:75%;">
                                <el-option label="社保新增" :value="0"></el-option>
                                <el-option label="社保续保" :value="1"></el-option>
                                <el-option label="社保停保" :value="2"></el-option>
                                <el-option label="社保变更" :value="3"></el-option>
                                <el-option label="社保补缴" :value="4"></el-option>
                                <el-option label="社保退保" :value="5"></el-option>
                            </el-select>
                        </div>
                        <div v-if="this.operationForm.operationType == 1">
                            <el-select v-model="addForm.applyType" :disabled="true" placeholder="请选择" style="width:75%;">
                                <el-option label="公积金新增" :value="6"></el-option>
                                <el-option label="公积金调入" :value="7"></el-option>
                                <el-option label="公积金封存" :value="8"></el-option>
                                <el-option label="公积金变更" :value="9"></el-option>
                                <el-option label="公积金补缴" :value="10"></el-option>
                                <el-option label="公积金退保" :value="11"></el-option>
                            </el-select>
                        </div>
                </el-form-item>
                <el-form-item label="字段名称:" prop="fieldName" v-if="this.operationDiaTitle == '编辑'">
                    <el-input v-model="addForm.fieldName" :disabled="true" placeholder="请输入字段名称" style="width:30%;"></el-input>
                </el-form-item>
                <el-form-item label="字段类型:" prop="fieldType" v-if="this.operationDiaTitle == '编辑'">
                    <el-select v-model="addForm.fieldType" :disabled="true" placeholder="请选择" style="width:30%;">
                        <el-option label="下拉选择类" :value="1"></el-option>
                        <el-option label="文本类" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="字段/资料编码Code:" v-if="show">
                    <el-input v-model="addForm.fieldCode" :disabled="true" style="width:30%;"></el-input>
                </el-form-item>
                <el-form-item label="是否必填:" prop="isRequired">
                    <el-radio v-model="addForm.isRequired" :label= "1" style="width:12%;">是</el-radio>
                    <el-radio v-model="addForm.isRequired" :label= "0" style="width:12%;">否</el-radio>
                </el-form-item>
            </el-form>
            <div v-if="this.addForm.fieldType == 1">
                <el-row :gutter="20">
                    <el-col :span="4">
                        <div class="grid-content bg-purple">
                            <el-button @click="addFieldContent" type="primary">添加</el-button>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <el-button size="small" @click="addFieldContents">批量导入</el-button>
                        </div>
                    </el-col>
                </el-row>
                <br/><br/>
                <el-form ref="fieldContentForm" :model="fieldContentForm">
                    <el-table
                        ref="fieldContentTable"
                        max-height="250"
                        :data="fieldContentForm.fieldContentList"
                        :cell-style="{padding:'0px 20px'}"
                        border
                        style="width: 100%; margin-bottom: 30px"
                       >
                        <el-table-column prop="index" label="序号" width="80"></el-table-column>
                        <el-table-column label="选项名称">
                            <template slot-scope="scope">
                                <el-form-item
                                :prop="'fieldContentList.' + scope.$index + '.typeName'">
                                    <el-input
                                        v-model="scope.row.typeName"
                                        placeholder="请输入"
                                        style="padding-top:20px;"
                                        maxlength="256">
                                    </el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="delFieldContent(scope.row)" type="text" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
            </div>
            <hr/><br/>
            <div style="text-align:right;">
                <el-button @click="operationFieldConfirm" type="primary">确定</el-button>
                <el-button @click="operationFieldClose">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="'添加地区'" class="popContainer" :visible.sync="addRegionDialog" width="670px" :before-close="addRegionClose">
            <hr/><br/>
            <el-transfer
                filterable
                style="margin-left:2px;"
                :titles="['可选城市', '已选城市']"
                :filter-method="filterMethod"
                filter-placeholder="请输入城市名称"
                v-model="value"
                :data="data">
            </el-transfer>
            <br/><hr/><br/>
            <div style="text-align:center;">
                <el-button @click="addRegionConfirm" type="primary">确定</el-button>
                <el-button @click="addRegionClose">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="'添加字段内容'" class="popContainer" :visible.sync="addFieldContentsDialog" width="30%" :before-close="addFieldContentsClose">
            <hr/><br/>
            <div >
                <el-form :model="fieldContentsForm" ref="fieldContentsForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="字段内容:" >
                        <el-input type="textarea" :rows="3" placeholder="请输入内容,多个请用中文；隔开。" v-model="fieldContentsForm.fieldContents" style="width:80%;"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <br/><hr/><br/>
            <div style="text-align:center;">
                <el-button @click="addFieldContentsConfirm" type="primary">确定</el-button>
                <el-button @click="addFieldContentsClose">取消</el-button>
            </div>
        </el-dialog>
    </div>

</template>
<script>
 
</script>>
<script>
    export default {
        name: "ApplyFieldOperation",
        components: {},
        data: function () {
            return {
                data: [],
                memory:[],
                value: [],
                show:false,
                tableLoading:false,
                operationForm:{
                    useTime:'',
                    addRegion:'',
                    operationType:''
                },
                fieldListForm:{
                    fieldList:[]
                },
                operationFieldDialog:false,
                operationFieldDataDialog:false,
                addRegionDialog:false,
                addFieldContentsDialog:false,
                addForm:{
                    applyType:'',
                    fieldName:'',
                    fieldCode:'',
                    fieldType:'',
                    isRequired:''
                },
                addDataForm:{
                    applyType:'',
                    fieldName:'',
                    fieldCode:'',
                    count:'',
                    fieldType:'',
                    isRequired:'',
                    dataSampleUrl:[],
                    emptyListUrl:[],
                    fieldContent:''
                },
                addRules:{
                    applyType:[{required:true,message:"申报类型不能为空",trigger:"blur"}],
                    fieldName:[{required:true,message:"字段名称不能为空",trigger:"change"}],
                    fieldType:[{required:true,message:"字段类型不能为空",trigger:"blur"}],
                    isRequired:[{required:true,message:"是否必填不能为空",trigger:"blur"}]
                },
                addDataRules:{
                    applyType:[{required:true,message:"申报类型不能为空",trigger:"blur"}],
                    fieldName:[{required:true,message:"资料名称不能为空",trigger:"change"}],
                    count:[{required:true,message:"资料份数不能为空",trigger:"change"}],
                    fieldType:[{required:true,message:"资料类型不能为空",trigger:"blur"}],
                    isRequired:[{required:true,message:"是否必填不能为空",trigger:"blur"}],
                },
                operationRules:{
                    useTime:[{required:true,message:"生效时间不能为空",trigger:"change"}],
                    addRegion:[{required:true,message:"适用地区不能为空",trigger:"change"}],
                    operationType:[{required:true,message:"业务类型不能为空",trigger:"blur"}]
                },
                fieldContentForm:{
                    fieldContentList:[]
                },
                operationDiaTitle:'',
                operationTitle:'',
                index:'',
                id:null,
                detailId:null,
                type:'',
                dataSampleUrl:'',
                emptyListUrl:'',
                isCopy:'',
                creator:'',
                fieldContentsForm:{
                    fieldContents:''
                }
            };
        },
        methods: {
            async confirmList(){  //提交申报字段或申报资料数据并返回列表页面
                let str = '';
                let details = [];
                let name = this.$cookies.get("name");
                if(name == null || name == "") name = "admin"
                this.fieldListForm.fieldList.forEach((x,index) => {
                    if(index == 0){
                        str += x.fieldName;
                    }else{
                        str = str + '、' + x.fieldName;
                    }
                    let obj = {};
                    obj.id = x.id;
                    obj.applyFieldId = x.applyFieldId;
                    if(this.isCopy == 1){
                        obj.id = 0;
                        obj.applyFieldId = 0;
                    }
                    obj.applyType = x.applyType;
                    obj.fieldCode = x.fieldCode;
                    obj.fieldName = x.fieldName;
                    obj.fieldType = x.fieldType;
                    obj.isRequired = x.isRequired;
                    obj.fieldContent = x.fieldContent;
                    obj.count = x.count;
                    if(x.dataSampleUrl != null && x.dataSampleUrl != ''){
                        obj.dataSampleUrl = x.dataSampleUrl;
                    }
                    if(x.emptyListUrl != null && x.emptyListUrl != ''){
                        obj.emptyListUrl = x.emptyListUrl;
                    }
                    if(x.fileType != null && x.fileType != ''){
                        obj.fileType = x.fileType;
                    }
                    details.push(obj);
                })
                this.$refs.operationForm.validate((valid) => {
                    if(valid){
                        let params = {
                            id:this.id,
                            area:this.operationForm.addRegion,
                            type:this.type,
                            applyInfo:str,
                            effectMonth:this.operationForm.useTime,
                            applyFieldDetails:details,
                            operationType:this.operationForm.operationType,
                            creator:this.creator,
                            username:name,
                            isCopyOpt:this.isCopy == 1 ? 1: 0
                        };
                        if(details.length < 1){
                            this.$message({
                                message: "申报字段设置没有填写数据",
                                type: "warning",
                                showClose: true,
                            });
                            return false;
                        }
                        if(this.isCopy == 1){
                            params.id = 0;
                        }
                        
                        let checkParams = {
                            addRegion: this.operationForm.addRegion,
                            type: this.type,
                            id: this.id
                        }
                        this.$api.ApplyField.AddRegionCheck(checkParams).then(res => {
                            if(res){
                                this.$confirm("已选择的地区中已有待提交、待审核数据，是否继续新增？", {
                                    confirmButtonText: "是",
                                    cancelButtonText: "否",
                                    type: "warning",
                                })
                                .then(() => {
                                    this.$api.ApplyField.EditApplyFieldInfo(params).then(res => {
                                        if(res){
                                            let activeName = '';
                                            if(this.type == 0){
                                                activeName = 'first';
                                            }
                                            if(this.type == 1){
                                                activeName = 'second';
                                            }
                                            this.$router.push({
                                                name: "ApplyFieldList",
                                                params: {
                                                    type: this.type,
                                                    activeName: activeName
                                                },
                                                query: {
                                                    cache: "false" // 设置缓存标志位
                                                }
                                            });
                                        }else{
                                            this.$message({
                                                message: "新增或修改失败",
                                                type: "warning",
                                                showClose: true,
                                            });
                                            return false;
                                        }
                                    });
                                }).catch(() => {
                                    return false;
                                });
                            }else{
                                this.$api.ApplyField.EditApplyFieldInfo(params).then(res => {
                                    if(res){
                                        let activeName = '';
                                        if(this.type == 0){
                                            activeName = 'first';
                                        }
                                        if(this.type == 1){
                                            activeName = 'second';
                                        }
                                        this.$router.push({
                                            name: "ApplyFieldList",
                                            params: {
                                                type: this.type,
                                                activeName: activeName
                                            },
                                            query: {
                                                    cache: "false" // 设置缓存标志位为 falsezhe
                                                }
                                        });
                                    }else{
                                        this.$message({
                                            message: "新增或修改失败",
                                            type: "warning",
                                            showClose: true,
                                        });
                                        return false;
                                    }
                                });
                            }
                        });
                    }else{
                        this.$message({
                            message: "请填好所有数据",
                            type: "warning",
                            showClose: true,
                        });
                        return false;
                    }
                });
            },
            returnList(){ // 返回列表页面
                // let activeName = '';
                // if(this.type == 0){
                //     activeName = 'first';
                // }
                // if(this.type == 1){
                //     activeName = 'second';
                // }
                // this.$router.push({
                //     name: "ApplyFieldList",
                //     params: {
                //         type: this.type,
                //         activeName: activeName
                //     },
                // });
                this.$router.go(-1); 
            },
            filterMethod(query, item) {  //穿梭框使用方法
                return item.pinyin.indexOf(query) > -1;
            },
            addRegion(){  //添加地区
                this.memory = this.value;
                this.addRegionDialog = true;
                let data = [];
                this.$api.policy.getDistrict().then(result => {
                    const cities = result.data;
                    cities.forEach((city, index) => {
                    data.push({
                        label: city,
                        key: city,
                        pinyin: cities[index]
                    });
                    });
                })
                if(this.operationForm.addRegion != null && this.operationForm.addRegion != ''){
                    let beforeValue = this.operationForm.addRegion.split('、');
                    this.value = beforeValue;
                }
                this.data = data;
            },
            addRegionConfirm(){  //提交添加地区
                this.addRegionDialog = false;
                if(this.operationForm.addRegion != ''||this.operationForm.addRegion != null){
                    this.operationForm.addRegion = '';
                }
                this.value.forEach((x,index) => {
                    if(index == 0){
                        this.operationForm.addRegion += x;
                    }else{
                        this.operationForm.addRegion = this.operationForm.addRegion + '、' + x;
                    }
                })
                if(this.value.length < 1){
                    this.operationForm.addRegion = '';
                }
            },
            addRegionClose(){  //关闭添加地区
                this.addRegionDialog = false;
                this.value = this.memory;
            },
            addField(){  //打开申报新增申报字段/资料弹窗
                if(this.type == 0){
                    this.addForm.fieldType = 0;
                    this.addForm.isRequired = 1;
                    this.operationFieldDialog = true;
                }
                if(this.type == 1){
                    this.addDataForm.fieldType = 0;
                    this.addDataForm.isRequired = 1;
                    this.operationFieldDataDialog = true;
                }
                this.operationDiaTitle = '新增';
            },
            edit(row){  //携带数据打开编辑申报字段/资料弹窗
                this.operationDiaTitle = '编辑';
                if(this.type == 0){ 
                    this.operationFieldDialog = true;
                    this.addForm = {
                        applyType: row.applyType,
                        fieldName: row.fieldName,
                        fieldCode: row.fieldCode,
                        fieldType: row.fieldType,
                        isRequired: row.isRequired
                    };
                    if(row.fieldContent != null){
                        let index = 1;
                        row.fieldContent.split('、').forEach(x => {
                            let obj = {};
                            obj.index = index;
                            obj.typeName = x;
                            this.fieldContentForm.fieldContentList.push(obj);
                            index += 1;
                        });
                    }
                    this.index = this.fieldListForm.fieldList.indexOf(row);
                    this.detailId = row.id;
                }
                if(this.type == 1){
                    this.operationFieldDataDialog = true;
                    this.addDataForm = {
                        applyType: row.applyType,
                        fieldName: row.fieldName,
                        fieldCode: row.fieldCode,
                        count: row.count,
                        fieldType: row.fieldType,
                        isRequired: row.isRequired,
                        fieldContent: row.fieldContent
                    }
                    if(row.dataSampleUrl != null && row.dataSampleUrl !=''){
                        let fileList = [];
                        let objData = {};
                        objData.name = row.dataSampleUrl.split('/')[row.dataSampleUrl.split('/').length - 1];
                        objData.url = row.dataSampleUrl;
                        fileList.push(objData);
                        this.addDataForm.dataSampleUrl = fileList;
                        this.dataSampleUrl = row.dataSampleUrl;
                    }else{
                        this.addDataForm.dataSampleUrl = [];
                    }
                    if(row.emptyListUrl != null && row.emptyListUrl !=''){
                        let fileList = [];
                        let objEmpty = {};
                        objEmpty.name = row.emptyListUrl.split('/')[row.emptyListUrl.split('/').length - 1];
                        objEmpty.url = row.emptyListUrl;
                        fileList.push(objEmpty);
                        this.addDataForm.emptyListUrl = fileList;
                        this.emptyListUrl = row.emptyListUrl;
                    }else{
                        this.addDataForm.emptyListUrl = [];
                    }
                    this.index = this.fieldListForm.fieldList.indexOf(row);
                    this.detailId = row.id;
                }
            },
            del(row){  //删除子表申报字段数据
                this.fieldListForm.fieldList.splice(this.fieldListForm.fieldList.indexOf(row),1);
            },
            operationFieldDataConfirm(){  //提交 新增/编辑申报资料，关闭弹窗
                debugger
                this.$refs.addDataForm.validate((valid) => {
                    if(valid){
                        if(this.addDataForm.fieldType == 1){
                            if(this.addDataForm.dataSampleUrl.length < 1 && this.addDataForm.emptyListUrl.length < 1){
                                this.$message({
                                    message: "资料模板和空表上传至少上传一个",
                                    type: "warning",
                                    showClose: true,
                                });
                                return false;
                            }
                        }
                        this.operationFieldDataDialog = false;
                        let obj = {};
                        obj.applyFieldId = 0;
                        obj.applyType = this.addDataForm.applyType;
                        obj.fieldName = this.addDataForm.fieldName;
                        obj.fieldCode = this.addDataForm.fieldCode;
                        obj.fieldType = this.addDataForm.fieldType;
                        obj.isRequired = this.addDataForm.isRequired;
                        obj.fieldContent = this.addDataForm.fieldContent;
                        obj.dataSampleUrl = this.dataSampleUrl;
                        obj.emptyListUrl = this.emptyListUrl;
                        obj.count = this.addDataForm.count;
                        if(obj.dataSampleUrl != null && obj.dataSampleUrl != ''){
                            obj.fileType = this.addDataForm.dataSampleUrl[0].name.split('.')[this.addDataForm.dataSampleUrl[0].name.split('.').length - 1];
                        }
                        if((obj.emptyListUrl != null && obj.emptyListUrl != '') && (obj.dataSampleUrl == null || obj.dataSampleUrl == '')){
                            obj.fileType = this.addDataForm.emptyListUrl[0].name.split('.')[this.addDataForm.emptyListUrl[0].name.split('.').length - 1];
                        }
                        if(this.operationDiaTitle == '新增'){
                            this.fieldListForm.fieldList.push(obj);
                        }
                        if(this.operationDiaTitle == '编辑'){
                            this.fieldListForm.fieldList.splice(this.index,1);
                            obj.id = this.detailId;
                            obj.applyFieldId = this.id;
                            this.fieldListForm.fieldList.splice(this.index,0,obj);
                            this.index = '';
                            console.log(obj);
                            console.log(this.fieldListForm.fieldList);
                        }
                        this.$refs['addDataForm'].resetFields();
                        this.addDataForm = {
                            applyType:'',
                            fieldName:'',
                            count:'',
                            fieldType:'',
                            isRequired:'',
                            dataSampleUrl:[],
                            emptyListUrl:[],
                            fieldContent:''
                        };
                        this.dataSampleUrl = '';
                        this.emptyListUrl = '';
                    } else {
                        this.$message({
                            message: "请填好所有数据",
                            type: "warning",
                            showClose: true,
                        });
                        return false;
                    }
                });
            },
            operationFieldConfirm(){ //提交新增/编辑申报字段，关闭弹窗
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.operationFieldDialog = false;
                        let obj = {};
                        obj.applyFieldId = 0;
                        obj.applyType = this.addForm.applyType;
                        obj.fieldName = this.addForm.fieldName;
                        obj.fieldCode = this.addForm.fieldCode;
                        obj.fieldType = this.addForm.fieldType;
                        obj.isRequired = this.addForm.isRequired;
                        let content = '';
                        this.fieldContentForm.fieldContentList.forEach((x,index) => {
                            if(index == 0){
                                content += x.typeName;
                            }else{
                                content = content + '、' + x.typeName;
                            }
                        })
                        obj.fieldContent = content;
                        if(content == ''){
                            obj.fieldContent = null;
                        }
                        if(obj.fieldType == 0){
                            obj.fieldContent = null;
                        }
                        if(this.operationDiaTitle == '新增'){
                            // 需求为：申请类型改为多选
                            // 为了尽量减少原代码的修改，这里单独加判断处理
                            if(Array.isArray(obj.applyType)){
                                obj.applyType.forEach(type => {
                                    let o = {
                                        applyFieldId: obj.applyFieldId,
                                        applyType: type,
                                        fieldName: obj.fieldName,
                                        fieldCode: obj.fieldCode,
                                        fieldType: obj.fieldType,
                                        isRequired: obj.isRequired,
                                        fieldContent: obj.fieldContent
                                    }
                                    this.fieldListForm.fieldList.push(o);
                                });
                            }else{
                                this.fieldListForm.fieldList.push(obj);
                            }
                        }
                        if(this.operationDiaTitle == '编辑'){
                            this.fieldListForm.fieldList.splice(this.index,1);
                            obj.id = this.detailId;
                            obj.applyFieldId = this.id;
                            this.fieldListForm.fieldList.splice(this.index,0,obj);
                            this.index = '';
                        }
                        this.fieldContentForm.fieldContentList = [];
                        this.$refs['addForm'].resetFields();
                        this.addForm = {
                            applyType:'',
                            fieldName:'',
                            fieldType:'',
                            isRequired:''
                        };
                    } else {
                        this.$message({
                            message: "请填好所有数据",
                            type: "warning",
                            showClose: true,
                        });
                        return false;
                    }
                });
            },
            operationFieldDataClose(){ //关闭新增/编辑弹窗
                this.operationFieldDataDialog = false;
                this.$refs['addDataForm'].resetFields();
                this.addDataForm = {
                    applyType:'',
                    fieldName:'',
                    count:'',
                    fieldType:'',
                    isRequired:'',
                    dataSampleUrl:[],
                    emptyListUrl:[],
                    fieldContent:''
                };
                this.dataSampleUrl = '';
                this.emptyListUrl = '';
            },
            operationFieldClose(){ //关闭新增/编辑弹窗
                this.operationFieldDialog = false;
                this.$refs['addForm'].resetFields();
                this.fieldContentForm.fieldContentList = [];
                this.addForm = {
                    applyType:'',
                    fieldName:'',
                    fieldType:'',
                    isRequired:''
                };
            },
            addFieldContent(){ //添加选项名称
                let fieldContentList = this.fieldContentForm.fieldContentList;
                if (fieldContentList == undefined) {
                    fieldContentList = new Array();
                }
                let obj = new Object();
                obj = {
                    index:this.fieldContentForm.fieldContentList.length + 1,
                    typeName: '',
                };
                fieldContentList.push(obj);
                this.fieldContentForm.fieldContentList = fieldContentList;
            },
            delFieldContent(obj){  //删除选项名称
                let index = obj.index;
                this.fieldContentForm.fieldContentList.splice(index-1, 1);
                let resetindex = 1;
                this.fieldContentForm.fieldContentList.forEach(o => {
                    o.index = resetindex;
                    resetindex = resetindex + 1;
                })
            },
            async getList(){  //获取子表数据
                let pm = this.$route.params.rowDetail;
                let params = {
                    applyFieldId: pm.id
                }
                this.tableLoading = true;
                    try {
                        let res = await this.$api.ApplyField.ApplyFieldDetailList(params);
                        if (res) {
                            this.fieldListForm.fieldList = res;
                        } else {
                            this.$message.error("查询出错");
                        }
                        this.tableLoading = false;
                    } catch (e) {
                        console.log(e);
                        this.tableLoading = false;
                }
            },
            handleDataRemove(file, fileList) {  //申报资料文件移除
                console.log(this.addDataForm.dataSampleUrl);
                this.addDataForm.dataSampleUrl = [];
                this.dataSampleUrl = '';
            },
            onChangeDataFile(file, fileList) {  //申报资料上传文件
                console.log(file, fileList);
                if (file.size > 5 * 1024 * 1024) {
                    this.$message({
                        message: "文件上传过大，请重新上传！",
                        type: "warning",
                        showClose: true,
                    });
                    this.addDataForm.dataSampleUrl = [];
                    this.dataSampleUrl = '';
                    this.$refs.dataUpload.clearFiles();
                    return;  
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传文件中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.addDataForm.dataSampleUrl.push(file.raw);
                if(this.addDataForm.dataSampleUrl.length > 0){
                    let fdData = new FormData();
                    fdData.append("file", this.addDataForm.dataSampleUrl[0]);
                    let applyType = '';
                    if(this.addDataForm.applyType == 0){
                        applyType = '社保新增';
                    }
                    if(this.addDataForm.applyType == 1){
                        applyType = '社保续保';
                    }
                    if(this.addDataForm.applyType == 2){
                        applyType = '社保停保';
                    }
                    if(this.addDataForm.applyType == 3){
                        applyType = '社保变更';
                    }
                    if(this.addDataForm.applyType == 4){
                        applyType = '社保补缴';
                    }
                    if(this.addDataForm.applyType == 5){
                        applyType = '社保退保';
                    }
                    if(this.addDataForm.applyType == 6){
                        applyType = '公积金新增';
                    }
                    if(this.addDataForm.applyType == 7){
                        applyType = '公积金调入';
                    }
                    if(this.addDataForm.applyType == 8){
                        applyType = '公积金封存';
                    }
                    if(this.addDataForm.applyType == 9){
                        applyType = '公积金变更';
                    }
                    if(this.addDataForm.applyType == 10){
                        applyType = '公积金补缴';
                    }
                    if(this.addDataForm.applyType == 11){
                        applyType = '公积金退保';
                    }
                    let fieldType = '';
                    if(this.addDataForm.fieldType == 0){
                        fieldType = '原件类';
                    }
                    if(this.addDataForm.fieldType == 1){
                        fieldType = '图像类';
                    }
                    fdData.append("fileName",this.addDataForm.fieldName + '_' + applyType + '_' + fieldType + Date.now() + '.' + this.addDataForm.dataSampleUrl[0].name.split('.')[this.addDataForm.dataSampleUrl[0].name.split('.').length - 1]);
                    let paramsData = fdData;
                    this.$api.ApplyField.UpLoadFile(paramsData).then(res => {
                        if(res.code == 200){
                            this.dataSampleUrl = res.data
                            loading.close();
                        }else{
                            loading.close();
                            this.$message.error("上传资料样本失败！");
                            this.addDataForm.emptyListUrl = [];
                            this.dataSampleUrl = '';
                            return;
                        }
                    });
                }
            },
            handleDataExceed(files, fileList) {  //申报资料上传文件超过1个判断
                this.$message.warning(`当前限制选择 1 个文件`);
            },
            handleEmptyRemove(file, fileList) {  //申报字段文件移除
                console.log(file, fileList);
                this.addDataForm.emptyListUrl = [];
                this.emptyListUrl = '';
            },
            onChangeEmptyFile(file, fileList) {  //申报字段上传文件
                console.log(file, fileList);
                if (file.size > 5 * 1024 * 1024) {
                    this.$message({
                        message: "文件上传过大，请重新上传！",
                        type: "warning",
                        showClose: true,
                    });
                    this.addDataForm.emptyListUrl = [];
                    this.dataSampleUrl = '';
                    this.$refs.emptyUpload.clearFiles();
                    return;  
                }
                const loading = this.$loading({
                    lock: true,
                    text: '上传文件中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.addDataForm.emptyListUrl.push(file.raw);
                if(this.addDataForm.emptyListUrl.length > 0){
                    let fdEmpty = new FormData();
                    fdEmpty.append("file", this.addDataForm.emptyListUrl[0]);
                    let applyType = '';
                    if(this.addDataForm.applyType == 0){
                        applyType = '社保新增';
                    }
                    if(this.addDataForm.applyType == 1){
                        applyType = '社保续保';
                    }
                    if(this.addDataForm.applyType == 2){
                        applyType = '社保停保';
                    }
                    if(this.addDataForm.applyType == 3){
                        applyType = '社保变更';
                    }
                    if(this.addDataForm.applyType == 4){
                        applyType = '社保补缴';
                    }
                    if(this.addDataForm.applyType == 5){
                        applyType = '社保退保';
                    }
                    if(this.addDataForm.applyType == 6){
                        applyType = '公积金新增';
                    }
                    if(this.addDataForm.applyType == 7){
                        applyType = '公积金调入';
                    }
                    if(this.addDataForm.applyType == 8){
                        applyType = '公积金封存';
                    }
                    if(this.addDataForm.applyType == 9){
                        applyType = '公积金变更';
                    }
                    if(this.addDataForm.applyType == 10){
                        applyType = '公积金补缴';
                    }
                    if(this.addDataForm.applyType == 11){
                        applyType = '公积金退保';
                    }
                    let fieldType = '';
                    if(this.addDataForm.fieldType == 0){
                        fieldType = '原件类';
                    }
                    if(this.addDataForm.fieldType == 1){
                        fieldType = '图像类';
                    }
                    fdEmpty.append("fileName",this.addDataForm.fieldName + '_' + applyType + '_' + fieldType + Date.now() + '.' + this.addDataForm.emptyListUrl[0].name.split('.')[this.addDataForm.emptyListUrl[0].name.split('.').length - 1]);
                    let paramsEmpty = fdEmpty;
                    this.$api.ApplyField.UpLoadFile(paramsEmpty).then(res => {
                        if(res.code == 200){
                            this.emptyListUrl = res.data
                            loading.close();
                        }else{
                            loading.close();
                            this.$message.error("上传空表上传失败！");
                            this.addDataForm.emptyListUrl = [];
                            this.dataSampleUrl = '';
                            return;
                        }
                    });
                }
            },
            handleEmptyExceed(files, fileList) {  //申报字段上传文件超过1个判断
                this.$message.warning(`当前限制选择 1 个文件`);
            },
            addFieldContents(){
                this.addFieldContentsDialog = true;
            },
            addFieldContentsConfirm(){
                debugger
                if(this.fieldContentsForm.fieldContents != '' && this.fieldContentsForm.fieldContents != null){
                    this.fieldContentsForm.fieldContents.split('；').forEach(x => {
                        if(x != '' && x != null){
                            let obj = {};
                            obj.index = this.fieldContentForm.fieldContentList.length + 1;
                            obj.typeName = x;
                            this.fieldContentForm.fieldContentList.push(obj);
                        }
                    })
                }
                this.addFieldContentsDialog = false;
                this.fieldContentsForm.fieldContents = '';
            },
            addFieldContentsClose(){
                this.addFieldContentsDialog = false;
                this.fieldContentsForm.fieldContents = '';
            },
            downLoad(row){  //下载文件
                if(row == null || row == ''){
                    this.$message({
                        message: "没有上传文件，请上传",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }else{
                    //下载方法2
                    const link = document.createElement('a')
                    link.setAttribute('href',row)
                    link.click()
                }
            },
            countChange(c){  //资料份数移入移出值变化处理
                this.addDataForm.count = c.target.value;
                console.log(this.addDataForm.count);
            },
            addRegionChange(a){ //添加地区移入移出值变化处理
                this.operationForm.addRegion = a.target.value;
            }
        },
        activated() {
            if(this.$route.params.rowDetail != null){  //判断有数据，则进入编辑，复制则是新增
                let pm = this.$route.params.rowDetail;
                this.operationForm = {
                    useTime: pm.effectMonth,
                    addRegion: pm.area,
                    operationType: pm.operationType
                };
                this.type = this.$route.params.type;
                this.isCopy = this.$route.params.isCopy;
                this.id = pm.id;
                this.creator = pm.creator;
                if(this.type == 0){
                    this.operationTitle = '编辑申报字段';
                    if(this.isCopy == 1){
                        this.operationTitle = '新增申报字段';
                    }
                }
                if(this.type == 1){
                    this.operationTitle = '编辑申报资料';
                    if(this.isCopy == 1){
                        this.operationTitle = '新增申报资料';
                    }
                }
                this.getList();
            }
            if(this.$route.params.operationType != null){  //进入新增
                this.operationForm.operationType = this.$route.params.operationType;
                this.type = this.$route.params.type;
                if(this.type == 0){
                    this.operationTitle = '新增申报字段';
                }
                if(this.type == 1){
                    this.operationTitle = '新增申报资料';
                }
            }
        },
    };
</script>
<style src="@/assets/public.css"></style>
<style scoped>
    #ApplyFieldOperation {
        padding: 20px;
    }

    .el-transfer-panel__filter .el-input__inner {
        width: 80%;
    }

    .op-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .row-input {
        width: 240px;
        margin: 0 20px 0 10px;
    }

    .table-container {
        background: #fff;
        padding: 0 20px 20px;
    }

    .popContainer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(0,0,0,0.3);
    }

    .control-panel {
        width: 100%;
        height: 48px;
        background: #fafafa;
        /* padding: 0 20px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid #ededed;
        box-sizing: border-box;
    }
</style>