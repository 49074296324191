<template>
  <div id="ApplyFieldList">
    <div class="page-header">
      <p class="page-path">
        申报设置<span class="slashline">/</span>申报字段设置
      </p>
      <p class="page-title">申报字段设置</p>
    </div>
    <div class="operation-pannel">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="字段设置" name="first"></el-tab-pane>
        <el-tab-pane label="资料设置" name="second"></el-tab-pane>
      </el-tabs>
      <el-form
        :model="filters"
        class="filters-form"
        label-width="110px"
        ref="filtersform"
      >
        <el-row :gutter="16">
          <el-col :span="4">
            <div v-if="this.activeName == 'first'">
              <el-form-item label="字段名称：" prop="ApplyInfo">
                <el-input
                  v-model="filters.ApplyInfo"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="this.activeName == 'second'">
              <el-form-item label="资料名称：" prop="ApplyInfo">
                <el-input
                  v-model="filters.ApplyInfo"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="4">
            <el-form-item label="适用地区：" prop="Region">
              <el-input
                v-model="filters.Region"
                clearable
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="审核状态：" prop="AuditStatus">
              <el-select
                v-model="filters.AuditStatus"
                clearable
                placeholder="请选择审核状态"
              >
                <el-option label="待提交" :value="0"></el-option>
                <el-option label="待审核" :value="1"></el-option>
                <el-option label="审核拒绝" :value="2"></el-option>
                <el-option label="审核通过" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="业务类型：" prop="OperationType">
              <el-select
                v-model="filters.OperationType"
                clearable
                placeholder="请选择业务类型"
              >
                <el-option label="社保" :value="0"></el-option>
                <el-option label="公积金" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="2">
            <el-button type="primary" @click="searchData">查询</el-button>
            <el-button type="default" @click="resetFilter()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="op-row">
        <el-dropdown @command="addApply">
          <el-button type="primary">
            新增<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="0">社保</el-dropdown-item>
            <el-dropdown-item :command="1">公积金</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          type="primary"
          @click="submitAudit"
          plain
          style="margin-left: 10px"
          >提交审核</el-button
        >
        <el-button @click="audit" type="success">审核</el-button>
        <el-button @click="editApply">编辑</el-button>
        <el-button @click="copyApply">复制</el-button>
        <el-button @click="withdrawApply">撤回</el-button>
        <el-button @click="delApply">删除</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
        max-height="500"
        :data="tableData"
        ref="tableData"
        border
        style="width: 100%; margin-bottom: 30px"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55" key="1"> </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="50"
          key="2"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="id"
          v-if="this.show"
          key="3"
        ></el-table-column>
        <el-table-column prop="operationType" label="业务类型" key="4">
          <template slot-scope="scope">
            <div v-if="scope.row.operationType == 0">社保</div>
            <div v-if="scope.row.operationType == 1">公积金</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="area"
          show-overflow-tooltip
          label="适用地区"
          key="5"
        ></el-table-column>
        <el-table-column
          prop="applyInfo"
          label="字段名称"
          show-overflow-tooltip
          v-if="this.activeName == 'first'"
          key="6"
        ></el-table-column>
        <el-table-column
          prop="applyInfo"
          label="资料名称"
          show-overflow-tooltip
          v-if="this.activeName == 'second'"
          key="6"
        ></el-table-column>
        <el-table-column prop="effectMonth" label="生效时间" key="7">
          <template slot-scope="scope">
            <div>
              {{
                commonjs.dateFtt("yyyy-MM-dd", new Date(scope.row.effectMonth))
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="creator" label="来源" key="8"></el-table-column>
        <el-table-column prop="auditStatus" label="审核状态" key="9">
          <template slot-scope="scope">
            <div v-if="scope.row.auditStatus == 0">待提交</div>
            <div v-if="scope.row.auditStatus == 1">待审核</div>
            <div v-if="scope.row.auditStatus == 2">审核拒绝</div>
            <div v-if="scope.row.auditStatus == 3">审核通过</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="editTime"
          label="修改时间"
          key="10"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <OptLog :menuId="menuId" :relationId="scope.row.id">
                <el-button @click="detailHandleClick(scope.row)"
              >查看详情</el-button>
            </OptLog>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalCount"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="'审核申报字段'"
      class="popContainer"
      :visible.sync="auditDialog"
      width="480px"
      :before-close="auditClose"
    >
      <hr />
      <el-form ref="auditForm" :model="auditForm" :rules="auditRules"
        ><br />
        <el-form-item label="是否通过:" prop="auditStatus">
          <el-radio
            v-model="auditForm.auditStatus"
            :label="3"
            style="width: 20%"
            >审核通过</el-radio
          >
          <el-radio
            v-model="auditForm.auditStatus"
            :label="2"
            style="width: 20%"
            >审核拒绝</el-radio
          >
        </el-form-item>
        <el-form-item label="审核意见:" style="margin-left: 10px">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请添加审核意见"
            v-model="auditForm.remark"
            style="width: 70%"
          ></el-input>
        </el-form-item>
      </el-form>
      <hr />
      <br />
      <div style="text-align: right">
        <el-button @click="auditConfirm" type="primary">确定</el-button>
        <el-button @click="auditClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ApplyFieldList",
  components: {},
  data: function () {
    return {
      menuId: "",
      activeName: "first",
      filters: {
        ApplyInfo: "",
        Region: "",
        AuditStatus: "",
        OperationType: "",
      },
      tableData: [],
      tableLoading: true,
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      type: 0,
      show: false,
      auditDialog: false,
      auditForm: {
        auditStatus: "",
        remark: "",
      },
      auditRules: {
        auditStatus: [
          { required: true, message: "必须选择是否通过", trigger: "blur" },
        ],
      },
    };
  },
  async created() {
    let menuId =  await this.$api.publicapi.getMenuId("申报设置-申报字段设置");
    this.menuId = menuId;
  },
  methods: {
    handleClick(tab, event) {
      //选择标签页
      console.log(tab, event);
      if (this.activeName == "first") {
        this.type = 0;
        this.pageSize = 10;
        this.currentPage = 1;
        this.totalCount = 0;
        this.getList();
      }
      if (this.activeName == "second") {
        this.type = 1;
        this.pageSize = 10;
        this.currentPage = 1;
        this.totalCount = 0;
        this.getList();
      }
      this.resetFilter();
    },
    resetFilter() {
      //重置查询条件
      this.filters = {};
      this.pageSize = 10;
      this.currentPage = 1;
      this.totalCount = 0;
      this.getList();
    },
    async getList() {
      //获取申报字段列表
      let params = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        type: this.type,
        applyInfo: this.filters.ApplyInfo,
        area: this.filters.Region,
        auditStatus: this.filters.AuditStatus,
        operationType: this.filters.OperationType,
      };
      this.tableLoading = true;
      try {
        let res = await this.$api.ApplyField.ApplyFieldInfoList(params);
        if (res) {
          this.totalCount = res.totalCount;
          this.tableData = res.item;
        } else {
          this.$message.error("查询出错");
        }
        this.tableLoading = false;
      } catch (e) {
        this.tableLoading = false;
      }
    },
    searchData() {
      //查询列表
      this.pageSize = 10;
      this.currentPage = 1;
      this.totalCount = 0;
      this.getList();
    },
    detailHandleClick(row) {
      //查看详情
      console.log(row);
      this.$router.push({
        name: "ApplyFieldDetail",
        params: {
          type: this.type,
          rowDetail: row,
        },
      });
    },
    indexMethod(index) {
      //实现表格序号自增
      let curpage = this.currentPage;
      let limitpage = this.pageSize;
      return index + 1 + (curpage - 1) * limitpage;
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getList();
    },
    addApply(command) {
      //新增
      this.$router.push({
        name: "ApplyFieldOperation",
        params: {
          type: this.type,
          operationType: command,
        },
      });
    },
    editApply() {
      //编辑
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要编辑的申报字段！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection.length > 1) {
        this.$message({
          message: "请选择一条数据进行编辑！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection[0].auditStatus == 1 || selection[0].auditStatus == 3) {
        this.$message({
          message: "请选择待提交/审核拒绝的数据进行编辑！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let row = selection[0];
      this.$router.push({
        name: "ApplyFieldOperation",
        params: {
          type: this.type,
          rowDetail: row,
        },
      });
    },
    copyApply() {
      //复制
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要复制的申报字段！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection.length > 1) {
        this.$message({
          message: "请选择一条数据进行复制！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let row = selection[0];
      let isCopy = 1;
      this.$router.push({
        name: "ApplyFieldOperation",
        params: {
          isCopy: isCopy,
          type: this.type,
          rowDetail: row,
        },
      });
    },
    delApply() {
      //删除
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要删除的申报字段！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let auditStatuss = [];
      let ids = [];
      let flag = true;
      selection.forEach((element) => {
        auditStatuss.push(element.auditStatus);
        ids.push(element.id);
      });
      let idsJson = JSON.stringify(ids);
      auditStatuss.forEach((x) => {
        if (x == 1 || x == 3) {
          flag = false;
        }
      });
      if (flag) {
        this.$confirm("是否删除数据？", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            this.$api.ApplyField.DelApplyField(idsJson).then((res) => {
              if (res) {
                this.$message({
                  message: "删除成功！",
                  type: "success",
                  showClose: true,
                });
                this.getList();
                return;
              } else {
                this.$message({
                  message: "后台出错，请联系管理员！",
                  type: "warning",
                  showClose: true,
                });
                return;
              }
            });
          })
          .catch(() => {
            return false;
          });
      } else {
        this.$message({
          message: "请选择待提交/审核拒绝的数据进行删除！",
          type: "warning",
          showClose: true,
        });
        return;
      }
    },
    withdrawApply() {
      //撤回
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要撤回的申报字段！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let auditStatuss = [];
      let ids = [];
      let flag = true;
      selection.forEach((element) => {
        auditStatuss.push(element.auditStatus);
        ids.push(element.id);
      });
      let idsJson = JSON.stringify(ids);
      auditStatuss.forEach((x) => {
        if (x != 1) {
          flag = false;
        }
      });
      if (flag) {
        this.$confirm("是否撤回所选择的待审核数据？", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            this.$api.ApplyField.WithdrawApplyField(idsJson).then((res) => {
              if (res) {
                this.$message({
                  message: "撤回成功！",
                  type: "success",
                  showClose: true,
                });
                this.getList();
                return;
              } else {
                this.$message({
                  message: "后台出错，请联系管理员！",
                  type: "warning",
                  showClose: true,
                });
                return;
              }
            });
          })
          .catch(() => {
            return false;
          });
      } else {
        this.$message({
          message: "请选择待审核的数据进行撤回！",
          type: "warning",
          showClose: true,
        });
        return;
      }
    },
    audit() {
      //审核
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要审核的申报字段！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection.length > 1) {
        this.$message({
          message: "请选择一条数据进行审核！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let auditStatuss = [];
      let ids = "";
      let flag = true;
      selection.forEach((element, index) => {
        auditStatuss.push(element.auditStatus);
        if (index == 0) {
          ids = ids + element.id;
        } else {
          ids = ids + "," + element.id;
        }
      });
      auditStatuss.forEach((x) => {
        if (x != 1) {
          flag = false;
        }
      });
      if (flag) {
        this.auditForm.auditStatus = 3;
        this.auditDialog = true;
      } else {
        this.$message({
          message: "请选择待审核的数据进行审核！",
          type: "warning",
          showClose: true,
        });
        return;
      }
    },
    auditConfirm() {
      //审核确定提交
      let selection = this.$refs.tableData.selection[0];
      this.$refs.auditForm.validate((valid) => {
        if (valid) {
          let params = {
            id: selection.id,
            auditStatus: this.auditForm.auditStatus,
          };
          if (this.auditForm.remark != null && this.auditForm.remark != "") {
            params.remark = this.auditForm.remark;
          }
          this.$api.ApplyField.AuditApplyField(params).then((res) => {
            if (res) {
              this.$message({
                message: "审核成功！",
                type: "success",
                showClose: true,
              });
              this.auditDialog = false;
              this.auditForm = {
                auditStatus: "",
                remark: "",
              };
              this.getList();
            } else {
              this.$message({
                message: "后台出错，请联系管理员！",
                type: "warning",
                showClose: true,
              });
              this.auditDialog = false;
              this.auditForm = {
                auditStatus: "",
                remark: "",
              };
              return;
            }
          });
        } else {
          this.$message({
            message: "请填好所有数据",
            type: "warning",
            showClose: true,
          });
          return false;
        }
      });
    },
    auditClose() {
      //关键审核弹窗
      this.auditDialog = false;
      this.auditForm = {
        auditStatus: "",
        remark: "",
      };
    },
    submitAudit() {
      //  提交审核
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要提交的申报字段！",
          type: "warning",
          showClose: true,
        });
        return;
      }
      let auditStatuss = [];
      let ids = [];
      let flag = true;
      selection.forEach((element) => {
        auditStatuss.push(element.auditStatus);
        ids.push(element.id);
      });
      let idsJson = JSON.stringify(ids);
      auditStatuss.forEach((x) => {
        if (x != 0) {
          flag = false;
        }
      });
      if (flag) {
        this.$confirm("是否提交所选择的待提交数据？", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning",
        })
          .then(() => {
            this.$api.ApplyField.UpdateAuditStatus(idsJson).then((res) => {
              if (res) {
                this.$message({
                  message: "提交审核成功！",
                  type: "success",
                  showClose: true,
                });
                this.getList();
                return;
              } else {
                this.$message({
                  message: "后台出错，请联系管理员！",
                  type: "warning",
                  showClose: true,
                });
                return;
              }
            });
          })
          .catch(() => {
            return false;
          });
      } else {
        this.$message({
          message: "请选择待提交的数据进行提交！",
          type: "warning",
          showClose: true,
        });
        return;
      }
    },
  },
  activated() {
    if (
      this.$route.params.type != undefined &&
      this.$route.params.activeName != undefined
    ) {
      this.type = this.$route.params.type;
      this.activeName = this.$route.params.activeName;
    }
    this.getList(); //进入页面刷新列表
  },
};
</script>
<style src="@/assets/public.css"></style>
<style scoped>
#ApplyFieldList {
  padding: 20px;
}

.op-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.row-input {
  width: 240px;
  margin: 0 20px 0 10px;
}

.table-container {
  background: #fff;
  padding: 0 20px 20px;
}

.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
}
</style>
