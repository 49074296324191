<template>
  <div id="addGeneralPolicy">
    <div class="page-header">
      <p class="page-path">
        账单管理<span class="slashline">/</span>台账模板管理<span
          class="slashline"
          >/</span
        >模板详情
      </p>
      <p class="page-title">费用模板详情</p>
    </div>
    <div class="operation-pannel">
      <el-collapse v-model="activeNames">
      <el-form
            :model="basicInfoForm"
            label-position="top"
            ref="basicInfoForm"
            class="basic-info-form"
            :rules="basicInfoFormRules"
          >
        <el-collapse-item name="1">
          <template slot="title">
            <div class="basic-info">基本模板信息</div>
          </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
            <el-row :gutter="30" >
              <el-col :span="10" >
                <el-form-item prop="type" >
                  <label style="width:154px;margin-left:84px"><span style="text-align:right">模板类型：</span></label>
                  <el-select
                    v-model="basicInfoForm.type"
                    placeholder="请选择"
                    :disabled="true"
                    @change="typeChange"
                    style="width:50%"
                  >
                      <el-option label="开户通用模板" :value="0"></el-option>
                      <el-option label="托管通用模板" :value="1"></el-option>
                      <el-option label="自定义网点托管模板" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item  prop="name">
                  <label style="width:154px;margin-left:84px"><span style="text-align:right">模板名称：</span></label>
                  <el-input
                    v-model="basicInfoForm.name"
                    placeholder=""
                    :disabled="true"
                    style="width:50%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row :gutter="30">
              <el-col :span="16">
                <el-form-item prop="remark">
                  <label style="width:154px;margin-left:105px"><span style="text-align:right">备&nbsp;注：</span></label>
                  <el-input
                    type = "textarea"
                    v-model="basicInfoForm.remark"
                    placeholder=""
                    :disabled="true"
                    :rows="4"
                    style="width:52%;margin-left:2px"
                    maxlength="200"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-collapse-item>
        <el-collapse-item name="2">
            <template slot="title">
              <div class="basic-info">版本详情</div>
            </template>
              <el-row :gutter="5">
                <label style="height:5px">.</label>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="10">
                   <el-form-item prop="effectMonth">
                    <label style="width:154px;margin-left:84px"><span style="text-align:right">生效月份：</span></label>
                   <el-date-picker
                   :editable="true"
                   :disabled="versionUpdate"
                   v-model="basicInfoForm.effectMonth"
                   type="month"
                   style="width:50%;"
                   value-format="yyyy-MM"
                   placeholder="请选择"
                >
                </el-date-picker>
                </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item prop="version">
                    <label style="width:112px;margin-left:70px;"><span style="text-align:right">版本：</span></label>
                    <el-select clearable v-model="basicInfoForm.version" @change="versionChange" placeholder="请选择"  style="width:50%;">
                      <el-option v-for="(item,index) in versionList"  :key="index" :label="item.flag==1?item.version+' 当前使用版本':item.version" :value="item.version"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                 <!-- <el-button @click="UpdateVersion" style="margin-left:100px;margin-top:32px;background-color:rgba(254,64,102,.25);text-align:center">版本更新</el-button> -->
              </el-row>
              <el-row :gutter="30">
                <el-col :span="12">
                   <el-form-item v-show="checkType" prop="branchesName">
                   <label style="width:154px;margin-left:84px"><span style="text-align:right">关联网点：</span></label>
                  <!-- <el-button @click="ChooseBranches" v-show="versionUpdate==true?false:true" style="background-color:rgba(254,64,102,.25);text-align:center">关联网点</el-button> -->
                  <el-input
                    type = "textarea"
                    v-model="basicInfoForm.branchesName"
                    placeholder="请点击选择关联网点"
                    :disabled="true"
                    style="width:70%;margin-left:2px"
                    :rows="4"
                    maxlength="512"
                  ></el-input>
                </el-form-item>
                </el-col>
              </el-row>
        </el-collapse-item>
         <el-collapse-item name="3">
          <template slot="title">
            <div class="basic-info">销售价设置</div>
          </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialSaleCost">
                  <label style="margin-left:57px;width:20%;text-align:right"><template>
                    <span v-if=" basicInfoForm.type == null">社保开户费用：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保开户费用：</span>
                    <span v-else>社保托管费用：</span>
                  </template></label>
                  <el-input
                    :span="3"
                    v-model="basicInfoForm.socialSaleCost"
                    placeholder="请输入"
                    :disabled="true"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="accSaleCost">
                  <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">公积金开户费用：</span>
                    <span v-else-if="basicInfoForm.type == 0">公积金开户费用：</span>
                    <span v-else>公积金托管费用：</span>
                  </template></label>
                  <el-input
                    v-model="basicInfoForm.accSaleCost"
                    placeholder="请输入"
                    :disabled="true"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialAndAccSaleCost">
                   <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">社保和公积金开户费用：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保和公积金开户费用：</span>
                    <span v-else>社保和公积金托管费用：</span>
                  </template></label>
                  <el-input
                    v-model="basicInfoForm.socialAndAccSaleCost"
                    placeholder="请输入"
                    :disabled="true"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-collapse-item>
         <el-collapse-item name="4">
          <template slot="title">
            <div class="basic-info">成本价设置</div>
          </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialSaleCost">
                  <label style="margin-left:57px;width:20%;text-align:right">
                  <template>
                    <span v-if=" basicInfoForm.type == null">社保开户成本：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保开户成本：</span>
                    <span v-else>社保托管成本：</span>
                  </template>
                  </label>
                  <el-input
                    :span="3"
                    v-model="basicInfoForm.socialCost"
                    placeholder="请输入"
                    :disabled="true"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                
                <el-form-item prop="accSaleCost">
                  <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">公积金开户成本：</span>
                    <span v-else-if="basicInfoForm.type == 0">公积金开户成本：</span>
                    <span v-else>公积金托管成本：</span>
                  </template></label>
                  <el-input
                    v-model="basicInfoForm.accCost"
                    placeholder="请输入"
                    :disabled="true"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialAndAccSaleCost">
                   <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">社保和公积金开户成本：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保和公积金开户成本：</span>
                    <span v-else>社保和公积金托管成本：</span>
                  </template></label>
                  <el-input
                    v-model="basicInfoForm.socialAndAccCost"
                    placeholder="请输入"
                    :disabled="true"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-collapse-item>
        </el-form>
      </el-collapse>
      <div class="bottom-btn-group">
        <el-button type="info" @click="returnGeneralList">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateInfo",
  components: {},
  data: function () {
    return {
      activeNames: ["1","2","3","4"],
      selectedRegion: [],
      checkType:false,
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      versionUpdate:true,
      versionList:[],
      basicInfoForm: {
        name: "",
        type: "",
        branches: "",
        branchesName: "",
        socialSaleCost: 0,
        accSaleCost: 0,
        socialAndAccSaleCost: 0,
        socialCost: 0,
        accCost: 0,
        socialAndAccCost: 0,
        remark: "",
        effectMonth:"",
        version:""
      },
      basicInfoFormRules: {
        region: [
          { required: true, message: "请选择所属地区", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择模板类型", trigger: "change" },
        ],
        effectiveTime: [
          { required: true, message: "请选择生效月份", trigger: "change" },
        ],
      },
      activeCard: "正常",

      savingTemplate: false, // 保存
      fanyouStorageDialog: false,
      fanyouStorageFilterForm: {
        Name: "",
      },
      Data: [],
      tableLoading: false,
      //网点
      BranchesDialog:false,
      BranchesFilterForm: {
        name: "",
        id:"",
        switchStatus:false
      },
      BranchName:"",
      BranchesData: [],
      BranchesTableLoading: false,
      BranchespageSize:10,
      BranchesCurrentPage: 1,
      BranchesTotalCount: 0,
      linkingTotable: false,
    };
  },
  methods: {
    async versionChange(version) {
      if(version!=null){
        let TemplateName = this.basicInfoForm.name;
        let Version = version;
        console.log(TemplateName + version);
        try {
          let res =await this.$api.BillManage.GetTemplateByNameAndVersion(TemplateName,Version);
          console.log(res);
          if (res) {
             this.basicInfoForm = res.data;
          } else
          {
            this.$message.error("查询模板明细信息出错！");
          }
        } catch (e) {
          console.log(e);
          this.$message.error(e.message);
        }
      }
    },
    getVersion()
    {
      if(this.versionList.length==0)
      {
        let TemplateName = this.basicInfoForm.name;
        console.log(TemplateName);
        console.log(this.basicInfoForm);
        this.$api.BillManage.GetVersionListByName(TemplateName).then(res=>{
          this.versionList=res.data;
        }).catch(()=>{ })
      }
    },
    indexMethod(index) { //实现表格序号自增
        let curpage = this.currentPage;
        let limitpage = this.pageSize;
        return (index + 1) + (curpage - 1) * limitpage;
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getLog();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getLog();
    },
    SubmitForm() {
      let validRes = [];
      this.$refs["basicInfoForm"].validate((valid) => {
        validRes.push(valid);
      });
      let res = validRes.every((item) => {
        return item;
      });
      if (res) {
        this.saveCostTemplate();
      } else {
        console.log("error submit!!");
      }
    },
    returnGeneralList() {
      // this.$router.push("/CostTemplateManage");
      this.$router.go(-1);  
    },
    async saveCostTemplate() {
      // 保存费用模板
      let params = this.basicInfoForm;
      this.savingTemplate = true;
      console.log(params);
      try {
        let res = await this.$api.BillManage.AddCostTemplate(params);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.savingTemplate = false;
          this.returnGeneralList();
        } else {
          this.savingTemplate = false;
          this.$message.error(res.msg);
        }
      } catch (e) {
        console.log(e);
        this.$message.error("保存失败！");
        this.savingTemplate = false;
      }
    },
    typeChange(type) {
      this.SetName(type);
    },
    async SetName(type) {
      console.log(type);
      this.GetcheckType();
      if(type == 0){
        this.basicInfoForm.name = "开户通用模板";
      }
      else if(type == 1){
        this.basicInfoForm.name = "托管通用模板";
      }else if(type==2){
        //获取最新的自定义网点托管模板名称
        this.basicInfoForm.name = "自定义网点托管模板V"+"1";
        let reqList = [];
         let req = this.$api.policy.getPolicyName({
          source: 0,
          district: 1,
          insuranceType: type,
        });
        reqList.push(req);
        try {
        let res = await Promise.all(reqList);
        let policyNameList = [];
        if (res) {
          res.forEach((item) => {
            policyNameList.push(item.data);
          });
        } else {
          policyNameList = [];
        }
        this.basicInfoForm.name = policyNameList.join(",");
      } catch (e) {
        console.log(e);
      } 
      }
    },
     GetcheckType(){
      if(this.basicInfoForm.type==2){
        this.checkType = true;
      }
      else{
        this.checkType = false;
      }
    },
    async getDetail() {
      let bid = this.$route.params.bid;
      try {
        let res = await this.$api.BillManage.CostTemplateDetail(bid);
        if (res.code==200) {
          this.basicInfoForm = res.data;
          //如有需要，在这重新赋值
          this.GetcheckType();
          this.getVersion();
        } else {
          this.basicInfoForm =[]
          this.$message.error("获取详情失败！");
        }
      } catch (e) {
        console.log(e);
        this.basicInfoForm =[]
        this.$message.error("获取详情失败！");
      }
    },
    // async getLog(){
    //   let bid = this.$route.params.bid;
    //   let LogParams = {
    //     pageCount: this.currentPage,
    //     takeCount: this.pageSize,
    //     templateId: bid,
    //   };
    //   this.tableLoading = true;
    //   try {
    //     let res = await this.$api.BillManage.CostTemplateLog(LogParams);
    //     console.log(res);
    //     console.log("日志加载")
    //     if (res) {
    //       this.totalCount = res.totalCount;
    //       this.tableData = res.item;
    //     } else {
    //       this.$message.error("获取日志失败！");
    //     }
    //     this.tableLoading = false;
    //   } catch (e) {
    //     console.log(e);
    //     this.$message.error("获取日志失败！");
    //     this.tableLoading = false;
    //   }
    // },
    //关于网点的方法
    BranchesCurrentChange(currentPage) {
      // 当前页码改变
      this.BranchesCurrentPage = currentPage;
      this.getBranchesList();
    },
    ChooseBranches() {
      this.getBranchesList();
    },
    //获取已被选中网点
    async GetHadSelectedBranches(){
      // let params = {
        
      // };
      return;
    },
    //获取网点信息
    async getBranchesList(){
       let params = {
        pageCount: this.BranchesCurrentPage,
        takeCount: this.BranchespageSize,
        branchName: this.BranchName,
        auditStatus: 1,
      };
      console.log(this.BranchName);
      console.log(params);
      this.BranchestableLoading = true;
      try {
        let res = await this.$api.branch.BranchList(params);
        let BranchesArr = "";
        if(this.basicInfoForm.branches>"")
        {
          BranchesArr = this.basicInfoForm.branches.split(';')
        }
        if (res) {
          res.item.forEach((item) => {
            if(BranchesArr.indexOf(item.id)>=0){
             item.switchStatus = true;
            }else{
              item.switchStatus = false;
            }
          });

          this.BranchesTotalCount = res.totalCount;
          this.BranchesData = res.item;
          this.BranchesDialog = true;
          console.log(this.BranchesData);
          console.log(res.item);
        } else {
          console.log("empty data");
          this.BranchesDialog = false;
        }
        this.BranchestableLoading = false;
      } catch (e) {
        console.log(e);
        this.BranchestableLoading = false;
        this.BranchesDialog = false;
      }
      
      this.BranchesData.forEach(row => {
            this.$refs["BranchesTable"].toggleRowSelection(row,row.switchStatus)
       });
    },
    //获取所有选中
    getBranchesSelected(){
      let branchSelectList = this.$refs["BranchesTable"].selection;
      console.log(branchSelectList);
      this.BranchesDialog = false;
      let nameStr = "";
      let ids = ""
      branchSelectList.forEach(br => {
        if(nameStr > ""){
          nameStr +=";"+br.name; 

        }else{
          nameStr =br.name; 
        }
        if(ids > ""){
          ids +=";"+br.id; 

        }else{
          ids =br.id; 
        }
      });
      this.basicInfoForm.branchesName = nameStr;
      this.basicInfoForm.branches = ids;
    },
    getRowKeys(row) {
      return row.id;
    },
  },
  mounted() {
    //获取明细
    this.getDetail();
    // this.pageSize = 10;
    // this.currentPage = 1;
    // this.getLog();
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#addGeneralPolicy {
  padding: 20px;
}
.basic-info {
  width: 100%;
  font-size: 14px;
  background: #fafafa;
  padding: 0 20px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #ededed;
}
.el-collapse {
  border-top: 0;
}
.basic-info-form {
  padding: 20px 20px 0;
}
.basic-info-form .el-select {
  width: 100%;
}
.basic-info-form .el-date-editor {
  width: 100%;
}
.policy-info {
  margin: 20px 0 0;
}
.control-panel {
  width: 100%;
  height: 48px;
  background: #fafafa;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
}
.uploadfileName {
  margin-left: 8px;
}
</style>
